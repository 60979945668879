import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ClienteService } from 'app/clientes/services/cliente.service';

import {
    MatDialog,
    MatPaginator,
    MatSnackBar,
    PageEvent
} from '@angular/material';

import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'app/base-module/base-service/auth.service';
import { CadastroRelacionamentoClienteComponent } from 'app/clientes/cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';
import { Subscription } from 'rxjs';
import { ActionDialog, BaseComponent } from '../../base-module/base-component/base.component';
import { CadastraAtividadeClientesComponent } from '../cadastra-atividade-clientes/cadastra-atividade-clientes.component';
import { FiltroAtividadesComponent } from '../filtro-atividades/filtro-atividades.component';
import { ResponderAtividadeComponent } from '../responder-atividade/responder-atividade.component';
import { RespostaAtividadeComponent } from '../resposta-atividade/resposta-atividade.component';
import { ListaAtividadeClienteService } from '../services/lista-atividade-clientes.service';


interface Actions {
    buttonNew: IItemsPermissionActions;
    buttonView: IItemsPermissionActions;
    buttonEdit: IItemsPermissionActions;
    buttonDelete: IItemsPermissionActions;
    buttonExport: IItemsPermissionActions;
}

@Component({
    selector: 'lista-todas-atividades',
    templateUrl: './lista-todas-atividades.component.html',
    styleUrls: ['./lista-todas-atividades.component.scss'],
    providers:[ClienteService]
})

export class ListaTodasAtividadesComponent extends BaseComponent implements OnInit{
    params: any = {};
    usuario:any;
    loadingIndicator: boolean;
    search = new FormControl("");
    inputSubscription: Subscription;
    isFiltered: boolean = false;
    is_priority: boolean = false;


    _actions: Actions = {
        buttonNew: {
            permission: 'Cadastrar Atividade da Lista de Atividades',
            enabled: false
        },
        buttonView: {
            permission: 'Visualizar Atividade da Lista de Atividades',
            enabled: false
        },
        buttonEdit: {
            permission: 'Editar Atividade da Lista de Atividades',
            enabled: false
        },
        buttonDelete:{
            permission: 'Excluir Atividade da Lista de Atividades',
            enabled: false
        },
        buttonExport:{
            permission: 'Exportar Atividade da Lista de Atividades',
            enabled: false
        }
    }; 

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaTodasAtividadesComponent, { static: false }) table: ListaTodasAtividadesComponent;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;

    constructor(public _service: ListaAtividadeClienteService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public _authService: AuthService,
                public route:Router) {
        super(dialog, snackBar);       
        this.verifyPermissionForActions();

        this.usuario = JSON.parse(this._authService.getUser()); 
    }  

    ngOnInit(): void {
        this.params.hoje_futuro = true;
        this.getAll();
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */

    pageEvent(event: PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    verifyPermissionForActions(){

        this._actions.buttonDelete.enabled = this._authService
        .isPermissao([this._actions.buttonDelete.permission]);

        this._actions.buttonEdit.enabled = this._authService
        .isPermissao([this._actions.buttonEdit.permission]);

        this._actions.buttonView.enabled = this._authService
        .isPermissao([this._actions.buttonEdit.permission]);

        this._actions.buttonNew.enabled = this._authService
        .isPermissao([this._actions.buttonNew.permission]);

        this._actions.buttonExport.enabled = this._authService
        .isPermissao([this._actions.buttonExport.permission]);        
    }


    /**
     * Consulta lista de empresas
     */
    getAll() {        

        this.loadingIndicator = true;
        
        this._service.getAll(this.params).subscribe(
            data => 
            {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }   

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastraAtividadeClientesComponent, {
            width: '700px',
            maxHeight: '99vh',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Atividade cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastraAtividadeClientesComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: { id: id, is_alterar_data: true }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Atividade atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

     /**
     * 
     * @param id 
     */
     delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Atividade",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();
                    
                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.closeLoading();
                            console.log("error: " + error);
                            lista.showError(error.message);

                        },
                        () => {
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltroAtividadesComponent, {
            width: '700px',
            maxHeight: '99vh',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != "cancel" && result != undefined){


                if(result.data_operacao_inicio != undefined && result.data_operacao_inicio != null){
                    this.params.data_operacao_inicio    = result.data_operacao_inicio;
                    this.params.data_operacao_fim       = result.data_operacao_fim;
                    this.params.hoje_futuro = 0;
                }else{
                    this.params.hoje_futuro = true;
                }
                
                if (result.cliente != undefined && result.cliente != null){
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.assunto_id != undefined && result.assunto_id != null) {
                    this.params.assunto_id = result.assunto_id;
                }

                if (result.atividade_concluidas != undefined && result.atividade_concluidas != null) {
                    this.params.atividade_concluidas = result.atividade_concluidas;
                }

                this.paginator.firstPage();
                
                this.getAll();
                this.isFiltered = true;
            }
        });
    }

    /**
     * Consulta lista
     */
    getAllExport() {

        this.openLoading();
        this._service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.showError(error.error.message);
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    clicaAcaoComercial(): void {
        this.is_priority = !this.is_priority
        if (this.is_priority) this.params.is_priority = this.is_priority
        else delete this.params.is_priority
        this.getAll();
    }


    redirecionaCliente(cliente){
        this.route.navigate([`clientes/cadastro/${cliente}/painel`]);
    }

    onActivate(row) {
        this.loadingIndicator = true;

        const atividade = {
            visualizado: true,
            atividade_id: row.atividade_id,
            cliente_id: row.cliente_id
        };
        
        this._service.update(row.id, atividade).subscribe(
            (data) => {                
                this.getAll();
            },
            (error) => {        
                this.loadingIndicator = false;        
                this.showError(error.error.message);
            },
            () => { }
        );
    }  

    openHistorico(row){

        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent , {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: row.cliente_id,
                dados_cliente: row.cliente,
                cliente: true,
                only_show: false,
                atividade_cliente_id: row.id,
                nome_assunto: row.nome_assunto,
                valor: row.valor
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }

            this.getAll();
        });
    }
    
    openResposta(row){  
        this.dialog.open(RespostaAtividadeComponent , {
            width: '500px',
            data: { 
                cliente_aceitou: row.cliente_aceitou,
                data_resposta: row.updated_at,
                observacao: row.observacao,
                valor: row.valor,
                assunto: row.nome_assunto,
                // type: row.cliente_oportunidade_id != null ? 'OP' : 'HR',
                // historico_relacionamento: row.historico_relacionamento
            }
        });
    }

    openResponderAtividade(row) {
        let dialogRef = this.dialog.open(ResponderAtividadeComponent , {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                id: row.id,
                cliente_aceitou: row.cliente_aceitou,
                data_resposta: row.data_resposta,
                observacao: row.observacao,
                valor: row.valor
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.direcionaHistorico(row);
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar a resposta");
            }
        });
    }

    direcionaHistorico(row) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '500px',
            disableClose: true,
            data: {
                title: "Resposta cadastrada com sucesso!",
                text: "Deseja adicionar um novo contato com cliente?",                
                callback: function () {
                    lista.openHistorico(row);
                }
            }
        });
    }  

    onEnter(value: string) {
        this.stopNow();
        this.params.search = value;
        this.search.setValue(value);
        this.getAll();
        this.isFiltered = true;
    }

    stopNow() {
        if (this.inputSubscription) {
            this.inputSubscription.unsubscribe();
        }
    }

    clearFiltro() {
        this.params = {hoje_futuro: true};
        this.getAll();
        this.is_priority = false;
        this.isFiltered = false;
    }

    exportar(){
        this.openLoading();
        this._service.getAllExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }
}