export const environment = {
    production      : true,
    staging         : true,
    hmr             : false,
    url             : "https://api.homolog.sistemaprosper.com.br/api/v1/",
    server          : "https://api.homolog.sistemaprosper.com.br/",
    urlFront        : "https://app.homolog.sistemaprosper.com.br/",
    publicKey       : "BAyfbJL4qBeB5ykwayUrf25odTySqEePOq6pCaS6Th4bGNBhPNLTDxSNC97aGKsvNNuS6OzS-O5PzcKCrx38FUo",
    keyPusher       : "dbc0e4916c72f029d375",
    SOCKET_ENDPOINT : 'https://realtime.sistemaprosper.com.br:49160',
    SOCKET_TOKEN    : 'ayUrf25odTySqEePOq6pCaS6Th4',
};

