import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: "home",
        title: "Home",
        type: "item",
        icon: "home",
        url: "/home",
        permissao: ["Home"],
    },
    /*{
        id       : 'dashboards',
        title    : 'Dashboards',
        type     : 'item',
        icon     : 'home',
        url      : '/home',
        permissao: ['Visualizar Dashboard']

    },*/
    // Agenda Com Especialista
    {
        id: "preAgendamento",
        title: "Agenda do Sócio",
        type: "collapsable",
        icon: "calendar_today",
        permissao: [
            "Listar Pré-Agendamento",
            "Dashboard Agenda do Especialista",
            "Listar Agenda do Especialista",
        ],
        children: [
            {
                id: "dashboardPreAgendamento",
                title: "Dashboard",
                type: "item",
                url: "/pre-agendamento/dashboard",
                permissao: ["Dashboard Agenda do Especialista"],
            },
            {
                id: "cadastroPreAgendamento",
                title: "Agenda com Especialista",
                type: "item",
                url: "/pre-agendamento/lista",
                permissao: [
                    "Listar Pré-Agendamento",
                    "Cadastrar Pré-agendamento",
                    "Visualizar Pré-agendamento",
                ],
            },
            {
                id: "cadastroPreAgendamento",
                title: "Agenda do Gestor",
                type: "item",
                url: "/pre-agendamento/agenda-socio",
                permissao: [
                    "Listar Call com Sócio",
                    "Cadastrar Call com Sócio",
                    "Visualizar Call com Sócio",
                ],
            },
            {
                id: "agendaEspecialistaPreAgendamento",
                title: "Datas Disponíveis",
                type: "item",
                url: "/pre-agendamento/agenda-especialista",
                permissao: [
                    "Listar Agenda do Especialista",
                    "Cadastrar Agenda do Especialista",
                ],
            },
        ],
    },
    // Ação Comercial
    {
        id: "acaoComercial",
        title: "Ação Comercial",
        type: "collapsable",
        icon: "assignment",
        permissao: ["Listar Ações Comerciais", "Dashboard Ação Comercial"],
        children: [
            {
                id: "dashboardAcaoComercial",
                title: "Dashboard",
                type: "item",
                url: "/acao-comercial/dashboard",
                permissao: ["Listar Ações Comerciais"],
            },
            {
                id: "listaAcaoComercial",
                title: "Consulta/Cadastro",
                type: "item",
                url: "/acao-comercial/consulta",
                permissao: [
                    "Listar Ações Comerciais",
                    "Cadastrar Ação Comercial",
                ],
            },
        ],
    },
    //Clientes
    {
        id: "clientes",
        title: "Clientes",
        type: "collapsable",
        icon: "people_outline",
        permissao: [
            "Listar clientes",
            "Listar Histórico Relacionamento do Cliente",
            "Listar Saldo de Cliente",
            "Lista de Clientes Limitada",
        ],
        children: [
            {
                id: "cadastroCliente",
                title: "Consulta/Cadastro",
                type: "item",
                icon: "group_add",
                url: "/clientes/consulta",
                permissao: ["Listar clientes"],
            },
            {
                id: "consultaCliente",
                title: "Consulta Clientes",
                type: "item",
                icon: "group_add",
                url: "/clientes/lista-limitada",
                permissao: ["Lista de Clientes Limitada"],
            },
            {
                id: "saldo-clientes",
                title: "Saldo em conta (corretora)",
                type: "item",
                icon: "attach_money",
                url: "/clientes/saldo",
                permissao: ["Listar Saldo de Cliente"],
            },
            {
                id: "historico-relacionamento",
                title: "Relacionamento/Oportunidade",
                type: "item",
                icon: "person_pin",
                url: "/clientes/historico-relacionamento",
                permissao: [
                    "Listar Histórico Relacionamento do Cliente",
                    "Listar Oportunidade"
                ],
            },
        ],
    },
    //PRODUTOS
    {
        id: "produtosServicos",
        title: "Produtos de Investimentos",
        type: "collapsable",
        icon: "attach_money",
        permissao: [
            "Listar Aporte",
            "Listar Basket",
            "Listar Boleta",
            "Listar Movimentação",
            "Listar Custódia Cliente",
            "Listar Histórico de Operações",
            "IRTRADE Cadastro",
            "IRTRADE Login",
            "Custódia Consolidada",
            "Lista de Operações Swing Trade",
            "Dashboard Comercial",
            "Dashboard Operacional",
            "Lista de Boleta de Pendente Renda Variavel",
            "Listar Boleta Consorcio",
            "Dashboard Consorcio",
        ],
        children: [
            // RENDA VARIAVEL
            {
                id: "movimentacao",
                title: "Renda Variável",
                type: "collapsable",
                icon: "attach_money",
                permissao: [
                    "Listar Aporte",
                    "Listar Basket",
                    "Listar Boleta",
                    "Listar Movimentação",
                    "Listar Custódia Cliente",
                    "Listar Histórico de Operações",
                    "IRTRADE Cadastro",
                    "IRTRADE Login",
                    "Custódia Consolidada",
                    "Lista de Operações Swing Trade",
                    "Dashboard Comercial",
                    "Dashboard Operacional",
                    "Listar Ordens Clientes",
                    "Lista Painel Renda Variavel",
                ],
                children: [
                    {
                        id: "dashboardRVComercial",
                        title: "Dashboard",
                        type: "item",
                        url: "/renda-variavel/dashboard-comercial",
                        permissao: ["Dashboard Comercial"],
                    },
                    {
                        id: "dashboardRVOperacional",
                        title: "Dashboard Operacional",
                        type: "item",
                        url: "/renda-variavel/dashboard-operacional",
                        permissao: ["Dashboard Operacional"],
                    },
                    {
                        id: "ordens-pendentes",
                        title: "Status de Operações",
                        type: "item",
                        url: "/clientes/ordens-pendentes",
                        permissao: ["Listar Ordens Clientes"],
                    },
                    {
                        id: "painelRV",
                        title: "Painel RV",
                        type: "item",
                        url: "/renda-variavel/painel-renda-variavel",
                        permissao: ["Lista Painel Renda Variavel"],
                    },
                    {
                        id: "basket",
                        title: "Basket",
                        type: "item",
                        url: "/renda-variavel/basket",
                        permissao: ["Listar Basket", "Visualizar Basket"],
                    },
                    {
                        id: "geradorOrdem",
                        title: "Gerador de Ordens",
                        type: "item",
                        url: "/renda-variavel/gerador-ordens",
                        permissao: [
                            "Lista Gerador de Ordens",
                            "Cadastrar Gerador de Ordens",
                            "Visualizar Gerador de Ordens",
                        ],
                    },
                    {
                        id: "swingTrade",
                        title: "Acompanhamento de Operações",
                        type: "item",
                        url: "/renda-variavel/swing-trade",
                        permissao: ["Lista de Operações Swing Trade"],
                    },
                    {
                        id: "boletaOperacao",
                        title: "Boleta de Estratégias",
                        type: "item",
                        url: "/movimentacao/boleta/1",
                        permissao: ["Listar Boleta"],
                    },
                    {
                        id: "boletaUnica",
                        title: "Boleta Única",
                        type: "item",
                        url: "/movimentacao/boleta-unica",
                        permissao: ["Listar Boleta"],
                    },
                    {
                        id: "aporte",
                        title: "Aporte/Retirada",
                        type: "item",
                        url: "/movimentacao/aporte-retirada",
                        permissao: ["Listar Aporte"],
                    },
                    {
                        id: "historicoCustodia",
                        title: "Histórico de Operações",
                        type: "item",
                        url: "/movimentacao/historico-custodia",
                        permissao: ["Listar Histórico de Operações"],
                    },
                    {
                        id: "historicoCustodiaEstruturadas",
                        title: "Operações Estruturadas",
                        type: "item",
                        url: "/renda-variavel/historico-operacao-estruturada",
                        permissao: ["Listar Histórico de Operações"],
                    },
                    {
                        id: "custodia",
                        title: "Custódia Consolidada",
                        type: "item",
                        url: "/movimentacao/custodia",
                        permissao: ["Listar Custódia Cliente"],
                    },
                    {
                        id: "historico",
                        title: "Movimentação Financeira MRV",
                        type: "item",
                        url: "/movimentacao/historico-movimentacao",
                        permissao: ["Listar Movimentação"],
                    },
                    {
                        id: "solucaoTributaria",
                        title: "Solução Tributária",
                        type: "collapsable",
                        permissao: ["IRTRADE Cadastro", "IRTRADE Login"],
                        children: [
                            {
                                id: "solucaoTributariaAcesso",
                                title: "Cadastre seu cliente",
                                type: "item",
                                url: "http://irtrade.com.br/?codigo=prosperidade",
                                externalUrl: true,
                                openInNewTab: true,
                                permissao: ["IRTRADE Cadastro"],
                            },
                            {
                                id: "solucaoTributariaAcesso",
                                title: "Acesse",
                                type: "item",
                                url: "https://parceiro.irtrade.com.br",
                                externalUrl: true,
                                openInNewTab: true,
                                permissao: ["IRTRADE Login"],
                            },
                        ],
                    },
                ],
            },
            //RENDA FIXA
            {
                id: "rendaFixa",
                title: "Renda Fixa",
                type: "collapsable",
                icon: "attach_money",
                permissao: [
                    "Listar Boleta Renda Fixa",
                    "Cadastrar Boleta Renda Fixa",
                    "Dashboard Renda Fixa",
                ],
                children: [
                    {
                        id: "dashboardRendaFixa",
                        title: "Dashboard",
                        type: "item",
                        url: "/renda-fixa/dashboard",
                        permissao: ["Dashboard Renda Fixa"],
                    },
                    {
                        id: "boletaRendaFixa",
                        title: "Boleta",
                        type: "item",
                        url: "/renda-fixa/lista-boleta-renda-fixa",
                        permissao: [
                            "Listar Boleta Renda Fixa",
                            "Cadastrar Boleta Renda Fixa",
                        ],
                    },
                    {
                        id: "comissionamentoRendaFixa",
                        title: "Comissionamento",
                        type: "item",
                        url: "/renda-fixa/comissionamento/renda-fixa",
                        permissao: [
                            "Lista de dados da importação secundário",
                            "Upload da Importação Secundário",
                        ],
                    },
                ],
            },
            //COE
            {
                id: "coe",
                title: "COE",
                type: "collapsable",
                icon: "attach_money",
                permissao: [
                    "Listar Boleta COE",
                    "Cadastrar Boleta COE",
                    "Dashboard COE",
                    "Lista de dados da importação secundário",
                ],
                children: [
                    {
                        id: "boletaDashboardCoe",
                        title: "Dashboard",
                        type: "item",
                        url: "/renda-fixa/dashboard-coe",
                        permissao: ["Dashboard COE"],
                    },
                    {
                        id: "boletaCoe",
                        title: "Boleta",
                        type: "item",
                        url: "/renda-fixa/lista-boleta-coe",
                        permissao: [
                            "Listar Boleta COE",
                            "Cadastrar Boleta COE",
                        ],
                    },
                    {
                        id: "comissionamentoCOE",
                        title: "Comissionamento",
                        type: "item",
                        url: "/renda-fixa/comissionamento/COE",
                        permissao: [
                            "Lista de dados da importação secundário",
                            "Upload da Importação Secundário",
                        ],
                    },
                ],
            },            
            //Previdencia
            {
                id: "previdencia",
                title: "Previdência",
                type: "collapsable",
                icon: "accessibility_new",
                permissao: ["Listar Boleta Previdência"],
                children: [
                    {
                        id: "boletaCoe",
                        title: "Boleta",
                        type: "item",
                        url: "/previdencia/boleta/consulta",
                        permissao: [
                            "Listar Boleta Previdência",
                            "Cadastrar Boleta Previdência",
                        ],
                    },
                ],
            },
            
        ],
    },
    //PARCEIROS
    {
        id: "parceiros",
        title: "Parceiros",
        type: "collapsable",
        icon: "business",
        permissao: [
            "Listar Boleta Consorcio", 
            "Dashboard Consorcio",
            "Listar Boleta Patrimonial",
            "Listar Planejamento Patrimonial",
            "Cadastrar Planejamento Patrimonial",
            "Listar Boleta Plano de Saúde",
            "Cadastrar Boleta Plano de Saúde"
        ],
        children: [
            //Consorcio
            {
                id: "consorcio",
                title: "Consórcio",
                type: "collapsable",
                icon: "attach_money",
                permissao: ["Listar Boleta Consorcio", "Dashboard Consorcio"],
                children: [
                    {
                        id: "dashboardConsorcio",
                        title: "Dashboard",
                        type: "item",
                        url: "/consorcio/dashboard",
                        permissao: ["Dashboard Consorcio"],
                    },
                    {
                        id: "boletaConsorcio",
                        title: "Boleta",
                        type: "item",
                        url: "/consorcio/lista-boleta-consorcio",
                        permissao: ["Listar Boleta Consorcio"],
                    },
                ],
            },
            // Crédito
            {
                id: "credito",
                title: "Crédito",
                type: "collapsable",
                icon: "attach_money",
                permissao: ["Listar Boleta de Crédito"],
                children: [
                    // {
                    //     id: "dashboardCredito",
                    //     title: "Dashboard",
                    //     type: "item",
                    //     url: "#",
                    //     permissao: ["Dashboard Consorcio"],
                    // },
                    {
                        id: "boletaCredito",
                        title: "Boleta",
                        type: "item",
                        url: "/credito/boleta/listar",
                        permissao: ["Listar Boleta de Crédito"],
                    },
                ],
            },
            //Plano de Saúde
            {
                id: "planoSaude",
                title: "Plano de Saúde",
                type: "collapsable",
                icon: "local_hospital",
                permissao: ["Listar Boleta Plano de Saúde"],
                children: [
                    // {
                    //     id: "dashboardPlanoSaude",
                    //     title: "Dashboard",
                    //     type: "item",
                    //     url: "#",
                    //     permissao: ["Dashboard Consorcio"],
                    // },
                    {
                        id: "boletaPlanoSaude",
                        title: "Boleta",
                        type: "item",
                        url: "/plano-saude/boleta/listar",
                        permissao: ["Listar Boleta Plano de Saúde"],
                    },
                ],
            },
            //Gestão Patrimonial
            {
                id: "gestaoPatrimonial",
                title: "Gestão Patrimonial",
                type: "collapsable",
                icon: "dashboard",
                permissao: [
                    "Listar Boleta Patrimonial",
                    "Listar Planejamento Patrimonial",
                    "Cadastrar Planejamento Patrimonial",
                ],
                children: [
                    {
                        id: "boletaPatrimonial",
                        title: "Boleta",
                        type: "item",
                        url: "/patrimonial/boleta/consulta",
                        permissao: [
                            "Listar Boleta Patrimonial",
                            "Cadastrar Boleta Patrimonial",
                        ],
                    },
                    {
                        id: "planejamentoPatrimonial",
                        title: "Planejamento",
                        type: "item",
                        url: "/patrimonial/lista-clientes",
                        permissao: [
                            "Listar Planejamento Patrimonial",
                            "Cadastrar Planejamento Patrimonial",
                            "Listar Proposta Cliente",
                        ],
                    },
                ],
            },
        ],
    },

    //Empreendimento
    {
        id: "empreendimento",
        title: "Empreendimento",
        type: "collapsable",
        icon: "business",
        permissao: ["Listar Central", "Listar Escritório", "Listar usuários"],
        children: [
            {
                id: "empresa",
                title: "Empresa",
                type: "item",
                icon: "business",
                url: "/empresas",
                permissao: ["Listar"],
            },
            {
                id: "central",
                title: "Central",
                type: "item",
                icon: "business",
                url: "/empreendimento/central",
                permissao: ["Listar Central"],
            },
            {
                id: "escritorio",
                title: "Escritório",
                type: "item",
                icon: "business",
                url: "/empreendimento/escritorio",
                permissao: ["Listar Escritório"],
            },
            {
                id: "diretor",
                title: "Diretores",
                type: "item",
                icon: "person",
                url: "/empreendimento/diretores",
                permissao: ["Listar usuários"],
            },
            {
                id: "operador",
                title: "Operador",
                type: "item",
                icon: "person",
                url: "/empreendimento/operador",
                permissao: ["Listar usuários"],
            },
            {
                id: "socioAdministrativo",
                title: "Administrativo",
                type: "item",
                icon: "person",
                url: "/empreendimento/administrativo",
                permissao: ["Listar usuários"],
            },
            {
                id: "assessor",
                title: "Assessor",
                type: "item",
                icon: "person",
                url: "/empreendimento/assessor",
                permissao: ["Listar usuários"],
            },
        ],
    },
    //Comissionamento
    {
        id: "comissionamento",
        title: "Comissionamento",
        type: "collapsable",
        icon: "money",
        permissao: [
            "Listar Perfil do Comissionamento",
            "Listar Divisão de Receita do Cliente",
            "Lista de dados da importação",
            "Listar Demonstrativo do Comissionamento"
        ],
        children: [
            {
                id: "dashboard-comissionamento",
                title: "Dashboard",
                type: "item",
                icon: "list",
                url: "/comissionamento/dashboard",
                permissao: ["Dashboard do Comissionamento"],
            },
            {
                id: "percentualClienteAssessor",
                title: "Divisão Receitas Clientes",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-cliente-usuarios",
                permissao: [
                    "Listar Divisão de Receita do Cliente",
                    "Cadastrar Divisão de Receita do Cliente",
                    "Editar Divisão de Receita do Cliente",
                    "Visualizar Divisão de Receita do Cliente",
                ],
            },
            {
                id: "importacao",
                title: "Demonstrativo Analítico",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-importacao",
                permissao: ["Lista de dados da importação"],
            },
            {
                id: "demonstrativo",
                title: "Desmonstrativo Sintético",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-demonstrativo",
                permissao: [
                    "Listar Demonstrativo do Comissionamento",
                    "Cadastrar Demonstrativo do Comissionamento",
                    "Editar Demonstrativo do Comissionamento",
                    "Visualizar Demonstrativo do Comissionamento",
                ],
            },
            {
                id: "tipo-evento",
                title: "Tipos de Eventos",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-tipos-eventos",
                permissao: ["Lista Tipo de Evento", "Cadastra Tipo de Evento"],
            },
            {
                id: "eventos-programados",
                title: "Eventos Programados",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-eventos-programados",
                permissao: [
                    "Listar Eventos Programados",
                    "Cadastrar Eventos Programados",
                ],
            },
            {
                id: "perfilUsuario",
                title: "Perfil Sócio",
                type: "item",
                icon: "list",
                url: "/comissionamento/lista-perfil",
                permissao: [
                    "Listar Perfil do Comissionamento",
                    "Cadastrar Perfil do Comissionamento",
                    "Visualizar Perfil do Comissionamento",
                ],
            },
        ],
    },
    // Prospecão de Cliente
    {
        id: "prospeccao-clientes",
        title: "Prospecção de Clientes",
        type: "collapsable",
        icon: "money",
        permissao: [
            "Listar dos Leads",
            "Cadastrar Lead",
            "Listar Atendimento Agendados dos Leads",
            "Listar Atendimento Futuros dos Leads",
        ],
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "item",
                icon: "list",
                url: "/prospeccao-clientes/dashboard",
                permissao: ["Listar dos Leads"],
            },
            {
                id: "listar-leads",
                title: "Leads",
                type: "item",
                icon: "list",
                url: "/prospeccao-clientes/listar-leads",
                permissao: ["Listar dos Leads", "Cadastrar Lead"],
            },
        ],
    },
    // Migração
    {
        id: "migracao",
        title: "Migração",
        type: "collapsable",
        icon: "assignment",
        permissao: ["Dashboar Migração"],
        children: [
            {
                id: "dashboardMigracao",
                title: "Dashboard",
                type: "item",
                url: "/migracao/dashboard",
                permissao: ["Dashboard Migração"],
            },
        ],
    },
    //Gerencial
    {
        id: "gerencialMenu",
        title: "Gerencial",
        type: "collapsable",
        icon: "assignment",
        permissao: ["Dashboard Gerencial"],
        children: [
            {
                id: "dashboardAcaoComercial",
                title: "Dashboard",
                type: "item",
                url: "/gerencial/dashboard",
                permissao: ["Dashboard Gerencial"],
            },
        ],
    },
    //Relatórios
    {
        id: "relatorios",
        title: "Relatórios",
        type: "collapsable",
        icon: "assessment",
        permissao: [
            "Relatório Net New Money",
            "Importar Relatório Net New Money",
            "Relatório Produção de Renda Fixa por Assessor",
            "Relatório Alocação Clientes",
            "Relatório Movimentação",
            "Relatório Posição Cliente",
        ],
        children: [
            {
                id: "clienteAlocacaoReport",
                title: "Alocação de Clientes",
                type: "item",
                icon: "money",
                url: "/relatorios/alocacao-clientes",
                permissao: ["Relatório Alocação Clientes"],
            },
            {
                id: "nnmReport",
                title: "Net New Money",
                type: "item",
                icon: "money",
                url: "/relatorios/net-new-money",
                permissao: [
                    "Relatório Net New Money",
                    "Importar Relatório Net New Money",
                ],
            },
            {
                id: "movimentacaoReport",
                title: "Movimentação",
                type: "item",
                icon: "money",
                url: "/relatorios/movimentacao",
                permissao: [
                    "Relatório Movimentação",
                    "Importar Relatório Movimentação",
                ],
            },
            {
                id: "positionReport",
                title: "Posição",
                type: "item",
                icon: "money",
                url: "/relatorios/posicao",
                permissao: [
                    "Relatório Posição Cliente",
                    "Importar Relatório Posição Cliente",
                ],
            },
            {
                id: "producaoRendaFixaReport",
                title: "Produção RF",
                type: "item",
                icon: "money",
                url: "/relatorios/renda-fixa-producao-assessor",
                permissao: ["Relatório Produção de Renda Fixa por Assessor"],
            },
        ],
    },
    //Configuração do Sistema e Auxiliares
    {
        id: "configuracaoSistema",
        title: "Configuração do Sistema",
        type: "group",
        permissao: [
            "Listar usuários",
            "Listar Grupos",
            "Listar Situação Tributária",
            "Listar Tipo de Central",
            "Listar Origem",
            "Listar Primeiro Contato",
            "Listar Status de Movimentação",
            "Listar Ativos",
            "Listar Ação de Relacionamento",
            "Listar Profissão",
            "Listar Tipo de Consorcio",
            "Listar Status de Carta Consorcio",
            "Listar Parceiro de Carta Consorcio",
            "Listar Operador de Carta Consorcio",
            "Listar Fechamento de Carta Consorcio",
        ],
        children: [
            {
                id: "auxiliares",
                title: "Auxiliares",
                type: "collapsable",
                icon: "event_note",
                permissao: [
                    "Listar Situação Tributária",
                    "Listar Tipo de Central",
                    "Listar Origem",
                    "Listar Primeiro Contato",
                    "Listar Status de Movimentação",
                    "Listar Ativos",
                    "Listar Profissão",
                    "Listar Ação de Relacionamento",
                    "Listar Caracteristicas da Previdência",
                    "Listar Caracteristicas da Gestão Patrimonial",
                    "Listar Tipo de Consorcio",
                    'Listar Assunto da Lista de Atividades',
                    "Cadastrar Tipo de Basket",
                    "Listar Status de Carta Consorcio",
                    "Listar Parceiro de Carta Consorcio",
                    "Listar Operador de Carta Consorcio",
                    "Listar Fechamento de Carta Consorcio",
                    "Listar Tipo de Lance de Carta Consorcio",
                ],
                children: [
                    // Agenda com especilista
                    {
                        id: "preAgendamentoAuxiliar",
                        title: "Agenda do Sócio",
                        type: "collapsable",
                        permissao: [
                            "Listar Objetivos Pré-Agendamento",
                            "Listar Status Pré-agendamento",
                        ],
                        children: [
                            {
                                id: "grupoPreAgendamento",
                                title: "Grupo de Especialistas",
                                type: "item",
                                url: "pre-agendamento/grupos",
                                permissao: ["Listar Objetivos Pré-Agendamento"],
                            },
                            {
                                id: "objetivoPreAgendamento",
                                title: "Objetivo",
                                type: "item",
                                url: "/auxiliares/pre-agendamento/objetivo",
                                permissao: ["Listar Objetivos Pré-Agendamento"],
                            },
                            {
                                id: "statusPreAgendamento",
                                title: "Status",
                                type: "item",
                                url: "/auxiliares/pre-agendamento/status",
                                permissao: ["Listar Status Pré-agendamento"],
                            },
                        ],
                    },
                    // Cliente
                    {
                        id: "clienteAuxiliar",
                        title: "Cliente",
                        type: "collapsable",
                        permissao: [
                            "Listar Ação de Relacionamento",
                            "Listar Primeiro Contato",
                            "Listar Situação Tributária",
                            "Listar Origem",
                            "Listar Profissão",
                            "Listar Tipo de Oportunidade",
                        ],
                        children: [
                            {
                                id: "acao",
                                title: "Ação",
                                type: "item",
                                url: "/auxiliares/acao",
                                permissao: ["Listar Ação de Relacionamento"],
                            },
                            {
                                id: "classificao-cliente",
                                title: "Classificação do Cliente",
                                type: "item",
                                url: "/auxiliares/primeiro-contato",
                                permissao: ["Listar Primeiro Contato"],
                            },
                            {
                                id: "situacao-tributaria",
                                title: "Situação Tributária",
                                type: "item",
                                url: "/auxiliares/situacao-tributaria",
                                permissao: ["Listar Situação Tributária"],
                            },
                            {
                                id: "origem-cliente",
                                title: "Origem do Cliente",
                                type: "item",
                                url: "/auxiliares/origem-cliente",
                                permissao: ["Listar Origem"],
                            },
                            {
                                id: "profissao",
                                title: "Profissão do Cliente",
                                type: "item",
                                url: "/auxiliares/profissao-cliente",
                                permissao: ["Listar Profissão"],
                            },
                            {
                                id: "tipo-oportunidade",
                                title: "Tipo de Oportunidades",
                                type: "item",
                                url: "/auxiliares/tipo-oportunidade",
                                permissao: ["Listar Tipo de Oportunidade"],
                            },
                            {
                                id: "tipo-custo",
                                title: "Tipo de Custos",
                                type: "item",
                                url: "/auxiliares/tipo-custos",
                                permissao: ["Listar Tipo de Custo"],
                            },
                            {
                                id: "tipo-receita",
                                title: "Tipo de Receitas",
                                type: "item",
                                url: "/auxiliares/tipo-receitas",
                                permissao: ["Listar Tipo de Receita"],
                            },
                            {
                                id: "tipo-patrimonio",
                                title: "Tipo de Patrimônio",
                                type: "item",
                                url: "/auxiliares/tipo-patrimonios",
                                permissao: ["Listar Tipo de Patrimonio"],
                            },
                        ],
                    },

                    // Renda Variavel
                    {
                        id: "auxiliaresRendaVariavel",
                        title: "Renda Variável",
                        type: "collapsable",
                        permissao: [
                            "Listar Situação Tributária",
                            "Listar Tipo de Central",
                            "Listar Origem",
                            "Listar Primeiro Contato",
                            "Listar Setores do Ativos",
                            "Listar Status de Movimentação",
                            "Cadastrar Tipo de Basket",
                            "Visualizar Tipo de Basket",
                            "Editar Tipo de Basket"
                        ],
                        children: [
                            {
                                id: "ativos",
                                title: "Ativos",
                                type: "item",
                                url: "/auxiliares/ativos",
                                permissao: [
                                    "Listar Ativos",
                                    "Listar Setores do Ativos",
                                ],
                            },
                            {
                                id: "setorRv",
                                title: "Setor",
                                type: "item",
                                url: "/auxiliares/setor",
                                permissao: ["Listar Setores do Ativos"],
                            },
                            {
                                id: "estretegiasRv",
                                title: "Estratégias RV",
                                type: "item",
                                url: "/auxiliares/estrategias-renda-variavel",
                                permissao: [
                                    "Lista de Estratégias de Renda Variável",
                                ],
                            },
                            {
                                id: "tipo-central",
                                title: "Tipo Central",
                                type: "item",
                                url: "/auxiliares/tipo-central",
                                permissao: ["Listar Tipo de Central"],
                            },
                            {
                                id: "status-movimentacao",
                                title: "Status da Movimentação",
                                type: "item",
                                url: "/auxiliares/status-movimentacao",
                                permissao: ["Listar Status de Movimentação"],
                            },
                            {
                                id: "tipo-basket",
                                title: "Tipo de Basket",
                                type: "item",
                                url: "/auxiliares/tipo-basket",
                                permissao: [
                                    "Cadastrar Tipo de Basket",
                                    "Visualizar Tipo de Basket",
                                    "Editar Tipo de Basket"
                                ],
                            },
                        ],
                    },
                    //COE
                    {
                        id: "auxiliaresCoe",
                        title: "COE",
                        type: "collapsable",
                        permissao: ["Listar Caracteristicas do COE"],
                        children: [
                            {
                                id: "caracteristicaCoe",
                                title: "Característica COE",
                                type: "item",
                                url: "/renda-fixa/lista-caracteristica-coe",
                                permissao: ["Listar Caracteristicas do COE"],
                            },
                        ],
                    },
                    //Renda Fixa
                    {
                        id: "auxiliaresRendaFixa",
                        title: "Renda Fixa",
                        type: "collapsable",
                        permissao: ["Listar Produtos da Renda Fixa"],
                        children: [
                            {
                                id: "produtoRendaFixa",
                                title: "Característica Renda Fixa",
                                type: "item",
                                url: "/renda-fixa/lista-produto-renda-fixa",
                                permissao: ["Listar Produtos da Renda Fixa"],
                            },
                        ],
                    },
                    //Previdencia
                    {
                        id: "auxiliaresPrevidencia",
                        title: "Previdência",
                        type: "collapsable",
                        permissao: ["Listar Caracteristicas da Previdência"],
                        children: [
                            {
                                id: "caracteristicaPrevidencia",
                                title: "Característica Previdência",
                                type: "item",
                                url: "auxiliares/previdencia/caracteristica",
                                permissao: [
                                    "Listar Caracteristicas da Previdência",
                                ],
                            },
                        ],
                    },
                    //Fundo Investimento
                    {
                        id: "auxiliaresFundoInvestimento",
                        title: "Fundo Investimento",
                        type: "collapsable",
                        permissao: [
                            "Cadastrar Fundo de Investimentos",
                            "Listar Fundo de Investimentos",
                            "Visualizar Fundo de Investimentos",
                        ],
                        children: [
                            {
                                id: "caracteristicaFundoInvestimento",
                                title: "Lista de Fundos Investimentos",
                                type: "item",
                                url: "auxiliares/fundo-investimentos",
                                permissao: ["Listar Fundo de Investimentos"],
                            },
                        ],
                    },
                    // Patrimonial
                    {
                        id: "auxiliaresPatrimonial",
                        title: "Gestão Patrimonial",
                        type: "collapsable",
                        permissao: [
                            "Listar Caracteristicas da Gestão Patrimonial",
                            "Listar Produtos",
                            "Cadastrar Produtos",
                            "Listar Seguradora Patrimonial",
                            "Cadastrar Seguradora Patrimonial"
                        ],
                        children: [
                            {
                                id: "caracteristicaPatrimonial",
                                title: "Característica Patrimonial",
                                type: "item",
                                url: "auxiliares/patrimonial/caracteristica",
                                permissao: [
                                    "Listar Caracteristicas da Gestão Patrimonial",
                                ],
                            },
                            {
                                id: "seguradoraPatrimonial",
                                title: "Seguradora",
                                type: "item",
                                url: "auxiliares/patrimonial/seguradoras",
                                permissao: [
                                    "Listar Seguradora Patrimonial",
                                    "Cadastrar Seguradora Patrimonial",
                                    "Visualizar Seguradora Patrimonial",
                                    "Alterar Seguradora Patrimonial",
                                    "Excluir Seguradora Patrimonial"

                                ],
                            },

                            {
                                id: "receitaAbordagem",
                                title: "Receitas",
                                type: "item",
                                url: "auxiliares/patrimonial/receita",
                                permissao: [
                                    "Listar Produtos",
                                    "Cadastrar Produtos",
                                ],
                            },
                            {
                                id: "despesaAbordagem",
                                title: "Despesas",
                                type: "item",
                                url: "auxiliares/patrimonial/despesa",
                                permissao: [
                                    "Listar Produtos",
                                    "Cadastrar Produtos",
                                ],
                            },
                            {
                                id: "outraInformacaoAbordagem",
                                title: "Outras Informações",
                                type: "item",
                                url: "auxiliares/patrimonial/outras-informacoes",
                                permissao: [
                                    "Listar Produtos",
                                    "Cadastrar Produtos",
                                ],
                            },
                            {
                                id: "produtoPatrimonial",
                                title: "Produtos da Proposta",
                                type: "item",
                                url: "auxiliares/patrimonial/produto-proposta",
                                permissao: [
                                    "Listar Produtos",
                                    "Cadastrar Produtos",
                                ],
                            },
                            {
                                id: "vigenciaPatrimonial",
                                title: "Vigência do Produto",
                                type: "item",
                                url: "auxiliares/patrimonial/vigencia-produto",
                                permissao: [
                                    "Listar Vigencia do Produto",
                                    "Cadastrar Vigencia do Produto",
                                ],
                            },
                        ],
                    },
                    //Comissionamento
                    {
                        id: "auxiliaresComissionamento",
                        title: "Comissionamento",
                        type: "collapsable",
                        permissao: [
                            "Listar Categoria do Comissionamento",
                            "Listar Mercado do Comissionamento",
                            "Listar Impostos do Comissionamento",
                            "Listar Função do Sócios",
                        ],
                        children: [
                            {
                                id: "mercadoComissionamento",
                                title: "Mercado",
                                type: "item",
                                url: "/comissionamento/lista-mercado",
                                permissao: [
                                    "Listar Mercado do Comissionamento",
                                    "Cadastrar Mercado do Comissionamento",
                                    "Editar Mercado do Comissionamento",
                                ],
                            },
                            {
                                id: "categoriaComissionamento",
                                title: "Categoria",
                                type: "item",
                                url: "/comissionamento/lista-categorias",
                                permissao: [
                                    "Listar Categoria do Comissionamento",
                                    "Cadastrar Categoria do Comissionamento",
                                    "Editar Categoria do Comissionamento",
                                ],
                            },
                            {
                                id: "impostosComissionamento",
                                title: "Impostos",
                                type: "item",
                                url: "/comissionamento/lista-impostos",
                                permissao: [
                                    "Listar Imposto do Comissionamento",
                                    "Cadastrar Imposto do Comissionamento",
                                    "Editar Imposto do Comissionamento",
                                ],
                            },
                            {
                                id: "funcaoSocioComissionamento",
                                title: "Função dos Sócios",
                                type: "item",
                                url: "/comissionamento/lista-funcao-socio",
                                permissao: [
                                    "Listar Função do Sócios",
                                    "Cadastrar Função do Sócios",
                                    "Editar Função do Sócios",
                                ],
                            },
                        ],
                    },
                    // Prospecção de clientes
                    {
                        id: "prospeccaoCleinteAuxiliar",
                        title: "Prospecção de Clientes",
                        type: "collapsable",
                        permissao: [
                            "Cadastrar Status Leads",
                            "Cadastrar Status Leads de Visita",
                            "Cadastrar Status de Atendimento dos Leads",
                            "Cadastrar Origem do Leads",
                            "Cadastrar Status de Visita e Atendimento dos Leads"
                        ],
                        children: [
                            {
                                id: "listar-status",
                                title: "Status de Lead",
                                type: "item",
                                url: "/prospeccao-clientes/listar-status",
                                permissao: [
                                    "Listar Status Leads",
                                    "Cadastrar Status Lead",
                                ],
                            },
                            {
                                id: "listar-status-visita-atendimento",
                                title: "Sub-Status do Lead",
                                type: "item",
                                url: "/prospeccao-clientes/listar-status-visita-atendimento",
                                permissao: [
                                    "Cadastrar Status de Visita e Atendimento dos Leads",
                                    "Visualizar Status de Visita e Atendimento dos Leads",
                                    "Alterar Status de Visita e Atendimento dos Leads"
                                ],
                            },
                            {
                                id: "listarIdentidadeLead",
                                title: "Lista de importação do Lead",
                                type: "item",
                                url: "/prospeccao-clientes/listar-identidade-lead",
                                permissao: [
                                    "Listar Origem do Leads", //todo: mudar para identidade
                                    "Cadastrar Origem do Lead",
                                ],
                            },
                            {
                                id: "listarOrigemLead",
                                title: "Origem do Lead",
                                type: "item",
                                url: "/prospeccao-clientes/listar-origem-lead",
                                permissao: [
                                    "Listar Origem do Leads",
                                    "Cadastrar Origem do Lead",
                                ],
                            },
                            {
                                id: "prioridadeLead",
                                title: "Prioridade do Lead",
                                type: "item",
                                url: "/auxiliares/prioridade-leads",
                                permissao: [
                                    "Listar Prioridade de Leads"
                                ],
                            },
                        ],
                    },
                    //Consórcio
                    {
                        id: "auxiliaresConsorcio",
                        title: "Consórcio",
                        type: "collapsable",
                        permissao: [
                            "Listar Tipo de Consorcio",
                            "Listar Empresa Consorcio",
                            "Listar Característica Produto Consorci",
                            "Listar Status de Carta Consorcio",
                            "Listar Parceiro de Carta Consorcio",
                            "Listar Operador de Carta Consorcio",
                            "Listar Fechamento de Carta Consorcio",
                            "Listar Tipo de Lance da Carta",
                        ],
                        children: [
                            {
                                id: "listarTipoConsorcio",
                                title: "Tipos de Operação",
                                type: "item",
                                url: "/consorcio/lista-tipo-consorcio",
                                permissao: ["Listar Tipo de Consorcio"],
                            },
                            {
                                id: "produtoConsorcio",
                                title: "Produtos",
                                type: "item",
                                url: "/consorcio/lista-caracteristica-produto-consorcio",
                                permissao: [
                                    "Listar Característica Produto Consorcio",
                                ],
                            },
                            {
                                id: "empresaConsorcio",
                                title: "Empresa",
                                type: "item",
                                url: "/consorcio/lista-empresa-consorcio",
                                permissao: ["Listar Empresa Consorcio"],
                            },
                            {
                                id: "parceiroCartaConsorcio",
                                title: "Parceiro",
                                type: "item",
                                url: "/auxiliares/parceiros-carta-consorcio",
                                permissao: ["Listar Parceiro de Carta Consorcio"],
                            },
                            {
                                id: "operadorCartaConsorcio",
                                title: "Operador",
                                type: "item",
                                url: "/auxiliares/operador-carta-consorcio",
                                permissao: ["Listar Operador de Carta Consorcio",],
                            },
                            {
                                id: "statusCartaConsorcio",
                                title: "Status Carta Consórcio",
                                type: "item",
                                url: "/auxiliares/status-carta-consorcio",
                                permissao: ["Listar Status de Carta Consorcio"],
                            },
                            
                            {
                                id: "fechamentoCartaConsorcio",
                                title: "Fechamento Carta Consórcio",
                                type: "item",
                                url: "/auxiliares/fechamento-carta-consorcio",
                                permissao: ["Listar Fechamento de Carta Consorcio"],
                            },

                            {
                                id: "tipoLanceCartaConsorcio",
                                title: "Tipo de Lance da Carta Consório",
                                type: "item",
                                url: "/auxiliares/lista-tipo-lance-carta-consorcio",
                                permissao: ["Listar Tipo de Lance da Carta"],
                            },
                        ],
                    },
                    //Lista de Atividades
                    {
                        id: "listaAtividadesAuxiliares",
                        title: "Lista de Atividades",
                        type: "collapsable",
                        permissao: [
                            "Listar Assunto da Lista de Atividades",
                            "Cadastrar Assunto da Lista de Atividades",
                        ],
                        children: [
                            {
                                id: "listarAssuntoListaAtividades",
                                title: "Lista de Assuntos",
                                type: "item",
                                url: "/lista-atividades/lista-assunto",
                                permissao: [
                                    "Listar Assunto da Lista de Atividades",
                                    "Cadastrar Assunto da Lista de Atividades"
                                ],
                            },                            
                        ],
                    },
                    //Plano de Saúde
                    {
                        id: "planoSaudeAuxiliares",
                        title: "Plano de Saúde",
                        type: "collapsable",
                        permissao: [
                            "Listar Prazo Plano de Saúde",
                            "Cadastrar Prazo Plano de Saúde",
                            "Listar Tipo Operacao Plano de Saúde",
                            "Cadastrar Tipo Operacao Plano de Saúde",
                            "Listar Parceiro Plano de Saúde",
                            "Cadastrar Parceiro Plano de Saúde",
                        ],
                        children: [
                            {
                                id: "listarPrazoPlanoSaude",
                                title: "Prazos",
                                type: "item",
                                url: "/auxiliares/plano-saude/lista-prazos",
                                permissao: [
                                    "Listar Prazo Plano de Saúde",
                                    "Cadastrar Prazo Plano de Saúde",
                                ],
                            },
                            {
                                id: "listarPrazoPlanoSaude",
                                title: "Tipos de Operações",
                                type: "item",
                                url: "/auxiliares/plano-saude/lista-tipo-operacoes",
                                permissao: [
                                    "Listar Tipo Operacao Plano de Saúde",
                                    "Cadastrar Tipo Operacao Plano de Saúde",
                                ],
                            },
                            {
                                id: "listarParceiroPlanoSaude",
                                title: "Parceiros",
                                type: "item",
                                url: "/auxiliares/plano-saude/lista-parceiros",
                                permissao: [
                                    "Listar Parceiro Plano de Saúde",
                                    "Cadastrar Parceiro Plano de Saúde",
                                ],
                            },
                            {
                                id: "listarOperadorPlanoSaude",
                                title: "Operadores",
                                type: "item",
                                url: "/auxiliares/plano-saude/lista-operadores",
                                permissao: [
                                    "Listar Operador Plano de Saúde",
                                    "Cadastrar Operador Plano de Saúde",
                                ],
                            },
                        ],
                    },
                    {
                        id: "creditoAuxiliares",
                        title: "Crédito",
                        type: "collapsable",
                        permissao: [
                            "Listar Operadora de Crédito",
                            "Cadastrar Operadora de Crédito",
                            "Listar Parceiro de Crédito",
                            "Cadastrar Parceiro de Crédito",
                            "Listar Prazo de Crédito",
                            "Cadastrar Prazo de Crédito",
                            "Listar Tipo de Operação de Crédito",
                            "Cadastrar Tipo de Operação de Crédito",
                            "Listar Boleta de Crédito",
                            "Cadastrar Boleta de Crédito",
                        ],
                        children: [
                            {
                                id: "listarTipoOperacaoCredito",
                                title: "Tipos de Operações",
                                type: "item",
                                url: "/auxiliares/credito/lista-tipo-operacoes",
                                permissao: [
                                    "Listar Tipo de Operação de Crédito",
                                    "Cadastrar Tipo de Operação de Crédito",
                                ],
                            },
                            {
                                id: "listarParceirosCredito",
                                title: "Parceiros",
                                type: "item",
                                url: "/auxiliares/credito/lista-parceiros",
                                permissao: [
                                    "Listar Parceiro de Crédito",
                                    "Cadastrar Parceiro de Crédito",
                                ],
                            },
                            {
                                id: "listarOperadorasCredito",
                                title: "Operadoras",
                                type: "item",
                                url: "/auxiliares/credito/lista-operadoras",
                                permissao: [
                                    "Listar Operadora de Crédito",
                                    "Cadastrar Operadora de Crédito",
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id: "administracao",
                title: "Administração",
                type: "collapsable",
                icon: "settings",
                permissao: [
                    "Listar usuários",
                    "Listar perfis de acesso",
                    '"Parâmetro do Sistema',
                ],
                children: [
                    {
                        id: "usuarios",
                        title: "Usuários",
                        type: "item",
                        icon: "person",
                        url: "/usuarios/lista",
                        permissao: ["Listar usuários"],
                    },
                    {
                        id: "grupos",
                        title: "Grupos",
                        type: "item",
                        icon: "group",
                        url: "/usuarios/grupos",
                        permissao: ["Listar perfis de acesso"],
                    },
                    {
                        id: "paraemtroSistema",
                        title: "Parâmetros do Sistema",
                        type: "item",
                        icon: "settings",
                        url: "/parametro-sistema",
                        permissao: ["Parâmetro do Sistema"],
                    },
                ],
            },
        ],
    },
    //Aplicativos
    {
        id: "aplicativos",
        title: "Aplicativos",
        type: "group",
        permissao: [
            "Listar Usuários Chat",
            "Listar Mensagens Chat",
            "Enviar Mensagem Chat",
            "Criar Pasta",
            "Enviar Arquivos",
            "Download Arquivos",
            "Listar Pasta e Arquivos",
        ],
        children: [
            {
                id: "centralNoticias",
                title: "Central de Notícias",
                type: "item",
                icon: "video_library",
                url: "/central-noticias",
                permissao: [
                    "Listar Noticias",
                    "Cadastrar Noticia",
                    "Visualizar Noticia",
                ],
            },
            {
                id: "chat",
                title: "Chat",
                type: "item",
                icon: "chat",
                url: "/chat",
                permissao: [
                    "Listar Usuários Chat",
                    "Listar Mensagens Chat",
                    "Enviar Mensagem Chat",
                ],
            },
            {
                id: "ged",
                title: "Gerenciador de Arquivos",
                type: "item",
                icon: "archive",
                url: "/file-manager",
                permissao: [
                    "Criar Pasta",
                    "Enviar Arquivos",
                    "Download Arquivos",
                    "Listar Pasta e Arquivos",
                ],
            },
        ],
    },
];
