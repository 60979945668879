import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { TipoProjetoService } from '../services/tipo-projeto.service';
import { ClienteProjetoService } from '../services/cliente-projeto.service';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};
export interface DialogData {
    id: any;
    cliente_id: any;
    
}

@Component({
    selector: 'app-cadastro-cliente-projeto',
    templateUrl: './cadastro-cliente-projeto.component.html',
    styleUrls: ['./cadastro-cliente-projeto.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },
          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}, TipoProjetoService
    ]
})

export class CadastroClienteProjetosComponent extends BaseComponent {
    @ViewChild(MatDatepicker, { static: false }) picker: MatDatepicker<any>;

    min_ano = new Date();

    tipo_projeto_id = new FormControl('', []);
    descricao = new FormControl('');
    quando = new FormControl('');
    valor  = new FormControl('0');

    model:any = {
        tipo_projeto_id:"",
        descricao:"",
        quando:"",
        valor:"",
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoProjeto: boolean = false;
    listaTipoProjeto: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClienteProjetoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClienteProjetosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _tipoProjetoService: TipoProjetoService)
    {
        super(dialog, snackBar);

        this.getTipoProjeto();
        
        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(): boolean{
        
        if (!this.tipo_projeto_id.valid || !this.tipo_projeto_id.value || this.tipo_projeto_id.value === '' || this.tipo_projeto_id.value === 'SELECIONE') {
            return true;
        }
    
        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            tipo_projeto_id: this.tipo_projeto_id.value,
            descricao: this.descricao.value,
            quando: this.quando.value._d,
            valor: this.valor.value,
            cliente_id: this.data.cliente_id
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }
    
    getTipoProjeto() {
        this.loadingSpinnerTipoProjeto = true;
        this._tipoProjetoService.getAll(this.params).subscribe({
            next: (request) => {
              this.listaTipoProjeto = request.data; // Atualiza a variável apenas após a resposta
              this.loadingSpinnerTipoProjeto = false;
            },
            error: (err) => {
                console.error('Erro ao buscar dados:', err);
                this.loadingSpinnerTipoProjeto = false;
            }
          });
    }

    
    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.quando.value && moment.isMoment(this.quando.value) 
        ? this.quando.value.clone() 
        : moment();
        ctrlValue.year(normalizedYear.year());
        this.quando.setValue(ctrlValue);
    }
    
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.quando.value && moment.isMoment(this.quando.value) 
        ? this.quando.value.clone() 
        : moment();
        ctrlValue.month(normalizedMonth.month());
        this.quando.setValue(ctrlValue);
        datepicker.close();
    }
    
    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.tipo_projeto_id.setValue(item.data.tipo_projeto_id)
                this.descricao.setValue(item.data.descricao)
                this.quando.setValue(item.data.quando)
                this.valor.setValue(item.data.valor)
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }
}

