import { 
    Component, 
    ViewChild, 
    TemplateRef,
    Output,
    EventEmitter,
    Input,
    OnInit
} from '@angular/core';

import {
    MatDialog, 
    MatSnackBar, 
    PageEvent
} from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { BaseComponent} from "../../base-module/base-component/base.component";

import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CadastroRelacionamentoClienteComponent } from '../cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';
import { CadastroOportunidadeComponent } from '../cadastro-oportunidade/cadastro-oportunidade.component';
import { CadastraAtividadeClientesComponent } from 'app/lista-atividades/cadastra-atividade-clientes/cadastra-atividade-clientes.component';
import { FiltrarGestaoRelacionamentoComponent } from '../filtrar-gestao-relacionamento/filtrar-gestao-relacionamento.component';
import { RespostaAtividadeComponent } from 'app/lista-atividades/resposta-atividade/resposta-atividade.component';

@Component({
    selector: 'app-lista-gestao-relacionamento',
    templateUrl: './lista-gestao-relacionamento.component.html',
    styleUrls: ['./lista-gestao-relacionamento.component.scss']
})

export class ListaGestaoRelacionamentoComponent extends BaseComponent implements OnInit{

    params: any = {};
    dataInicio: string = '';
    dataFim: string = '';  
    user: any;
    data: any;
    loadingIndicator: boolean = false;
    data_atualizacao: any
    todayDate: Date = new Date();
    inputSubscription: Subscription;
    
    @Input() search: FormControl;
    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaGestaoRelacionamentoComponent, { static: false }) table: ListaGestaoRelacionamentoComponent;

    @ViewChild('table',{ static: false }) table2: DatatableComponent;

    constructor(public _service: ClienteRelacionentoService, 
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private _authService: AuthService) {
        super(dialog, snackBar);
        this.user = JSON.parse(this._authService.getUser());
        
    }

    ngOnInit(): void {
        if(this.user.tipo_usuario_id == 1){
            this.getAll();
        }
        this.search.valueChanges.subscribe(val => {
            this.stopNow();
            this.params.search = val;
            this.getAll()
        })
        
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    
    /**
     * Evento de ordenação da lista de produtos
     * @param event
    */
    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }  

    /**
     * Consulta lista de empresas
     */
    getAll() {
        this.loadingIndicator   = true;                
        this.inputSubscription = this._service.getAllGestaoRelacionamento(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
            },
            () => {                
                this.loadingIndicator = false;
            }
        );
    }

    stopNow() {
        this.inputSubscription.unsubscribe();
    }

    onEnter(value: string) {
        this.stopNow();
        this.params.search = value;
        this.getAll();
    }

    exportar(){
        if (!this.dataInicio || !this.dataFim) {
            this.snackBar.open('Por favor, filtrar datas de início e fim.', 'Fechar', { duration: 5000, verticalPosition: 'top' });
            return;
        }
        this.openLoading();
        this._service.getAllGestaoRelacionamentoExport(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');

            },
            error => {                
                this.closeLoading();
            },
            () => {                
                this.closeLoading();
            }
        );
    }

    openCadastroRelacionamento(): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent , {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: null,
                cliente: true,
                only_show: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento Cadastrado com Sucesso!");                
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar o registro");
            }

            this.getAll();
        });
    }

    openAlterarRelacionamento(id): void {
        let dialogRef = this.dialog.open(CadastroRelacionamentoClienteComponent, {
            width: '1200px',
            maxHeight: '99vh',
            data: { 
                cliente_id: null,
                id: id,
                cliente: false,
                only_show: false
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Relacionamento atualizado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar atualizar o registrod de relacionamento com cliente");
            }
        });
    } 

    openCadastroOportunidade(): void {
        let dialogRef = this.dialog.open(CadastroOportunidadeComponent, {
            width: "500px",
            data: {
                cliente_id: null,
                cliente: true,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Oportunidade cadastrada com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar o registro."
                );
            }
        });
    }

    /**
     * Modal de cadastro
     */
     openCadastroAtividade(): void {
        let dialogRef = this.dialog.open(CadastraAtividadeClientesComponent, {
            width: '700px',
            maxHeight: '99vh',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Atividade cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar um novo registro.");
            }
        });
    }

    /**
     * Modal de edição
     */
     openAlterarAtividade(id): void {
        let dialogRef = this.dialog.open(CadastraAtividadeClientesComponent, {
            width: '700px',
            maxHeight: '99vh',
            data: { id: id }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Atividade atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

     /**
     * Modal de edição
     */
     openFiltrar(): void {
        let dialogRef = this.dialog.open(FiltrarGestaoRelacionamentoComponent, {
            width: '700px',
            maxHeight: '99vh',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                if (result.data_inicio != undefined && result.data_inicio != null) {                    
                    this.params.data_inicio = result.data_inicio;
                    this.dataInicio = result.data_inicio;                    
                }

                if (result.data_fim != undefined && result.data_fim != null) {
                    this.params.data_fim = result.data_fim;
                    this.dataFim = result.data_fim;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.acao_id != undefined && result.acao_id != null) {
                    this.params.acao_id = result.acao_id;
                }

                if (result.cliente != undefined && result.cliente != null) {
                    this.params.cliente_id = result.cliente.id;
                }else{
                    this.params.cliente_id = '';
                }

                if (result.tipo_registro != undefined && result.tipo_registro != null) {
                    this.params.tipo_registro = result.tipo_registro;
                }

                if (result.assunto_id != undefined && result.assunto_id != null) {
                    this.params.assunto_id = result.assunto_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    isResponse(row) {
        return ( row.data_resposta != null && row.tipo_registro != 'Histórico de Relacionamento'); 
    }

    openResposta(row){
        this.dialog.open(RespostaAtividadeComponent, {
            width: '500px',
            data: { 
                cliente_aceitou: row.cliente_aceitou,
                data_resposta: row.data_resposta,
                observacao: row.observacao_resposta,
                valor: row.valor_resposta,
                assunto: row.assunto
            }
        });
    }

    getObservacao(row){
        if(row.historico_relacionamento){
            return row.historico_relacionamento[0].observacao;
        }
        return row.observacao_resposta;
    }

}
