import {
    Component,
    OnInit
} from '@angular/core';

import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from 'app/base-module/base-component/base.component';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ModalShowAtividadeComponent } from 'app/lista-atividades/modal-show-atividade/modal-show-atividade.component';
import { ListaAtividadeService } from 'app/lista-atividades/services/lista-atividades.service';

import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';


interface Actions {
    listaAtividadesAssessor: IItemsPermissionActions;
    listaAtividadeEspecialista: IItemsPermissionActions;
}
@Component({
    selector: 'lista-atividades',
    templateUrl: './lista-atividades.component.html',
    styleUrls: ['./lista-atividades.component.scss'],
    providers: [ ListaAtividadeService ],
    animations   : fuseAnimations
})

export class ListaAtividadesComponent extends BaseComponent implements OnInit {
    
    user:any;
    loadingIndicator:boolean = false;
    meta:any;
    atividadeText: string = "Lista de Atividades do Assessor";
    routePageActivity = "/lista-atividades/atividades"
    isAssessor: boolean = true;

    _actions: Actions = {
        listaAtividadesAssessor: {
            permission: 'Listar Atividade da Lista de Atividades',
            enabled: false
        },
        listaAtividadeEspecialista: {
            permission: 'Visualizar Atividades de Especialista',
            enabled: false
        }
    }; 

    constructor(public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        public _route: Router,
        private _service: ListaAtividadeService) { 
            super(dialog, snackBar);
            this.user = JSON.parse(this._authService.getUser());
            this.verifyPermissionForActions();
    }

    ngOnInit() {
        this.getAll();
        console.log(this.user);
    }

    verifyPermissionForActions(){
        if (this._authService.isPermissao([this._actions.listaAtividadeEspecialista.permission])){
            this._actions.listaAtividadeEspecialista.enabled = true;
        }
        if (this._authService.isPermissao([this._actions.listaAtividadesAssessor.permission])){
            this._actions.listaAtividadesAssessor.enabled = true;
        }

        if (!this._actions.listaAtividadesAssessor.enabled && this._actions.listaAtividadeEspecialista.enabled){
            this.atividadeText = "Lista de Atividades do Especialista";
            this.isAssessor = false;
            this.routePageActivity = "/lista-atividades/atividades-especialista"
        }
    }


    getAll(){
        this.loadingIndicator = true;
        this._service.getAll({
            assessor_id: this.user.id,
            hoje: true,
            is_assessor: this.isAssessor
        }).subscribe(
            data => {
                this.rows = data.data;
                this.meta = data.meta;
                this.temp = [...this.rows];
        },
            error => {
                this.loadingIndicator = false;
            },
        () => {
            this.loadingIndicator = false;
        });    
    }

    handleAtividade(){
        this._route.navigate([this.routePageActivity]);
    }

    onClickNotification(e) {
        if(e.type == 'click'){
            const dialogRef =  this.dialog.open(ModalShowAtividadeComponent, {
                data: { 
                    dados: e.row
                }
            });
        }        
    }

    getNome(row){
        if(row.atividade_clientes && row.atividade_clientes.length > 0 && row.atividade_clientes[0].cliente){
            if(row.atividade_clientes[0].cliente.numero_xp && row.atividade_clientes[0].cliente.nome){
                return row.atividade_clientes[0].cliente.numero_xp+'-'+ row.atividade_clientes[0].cliente.nome;
            }else{
                return row.atividade_clientes[0].cliente.nome;
            }
        }else{
            return row.lista_clientes;
        }
    }

}
