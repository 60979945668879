import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { ServiceWorkerModule } from '@angular/service-worker';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { HomeModule } from 'app/home/home.module';
import { LayoutModule } from 'app/layout/layout.module';
import { NgxHorizontalTimelineModule } from 'ngx-horizontal-timeline';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routing';
import { AppsModule } from './apps/apps.module';
import { BaseModule } from './base-module/base.module';
import { NotificacaoModule } from './notificacao/notificacao.module';
import { ParametrosModule } from './parametros/parametros.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes,{
            useHash: environment.staging ? true : false,
        }),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
        BaseModule,
        AppsModule,
        NotificacaoModule,
        ParametrosModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
        NgxHorizontalTimelineModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule { }
