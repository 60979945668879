import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { RegionalService } from 'app/empreendimento/services/regional.service';
import { EscritorioService } from 'app/empresas/services/escritorio.service';
import { MovimentacaoModule } from 'app/movimentacao/movimentacao.module';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { AuthService } from '../base-module/base-service/auth.service';
import { BaseModule } from "../base-module/base.module";
import { CadastroBoletaConsorcioComponent } from './cadastro-boleta-consorcio/cadastro-boleta-consorcio.component';
import { CadastroCaracteristicaProdutoConsorcioComponent } from './cadastro-caracteristica-produto-consorcio/cadastro-caracteristica-produto-consorcio.component';
import { CadastroCarteiraConsorcioComponent } from './cadastro-carteira-consorcio/cadastro-carteira-consorcio.component';
import { CadastroEmpresaConsorcioComponent } from './cadastro-empresa-consorcio/cadastro-empresa-consorcio.component';
import { CadastroTipoConsorcioComponent } from './cadastro-tipo-consorcio/cadastro-tipo-consorcio.component';
import { ConsorcioRoutes } from "./consorcio.routing";
import { DashboardConsorcioComponent } from './dashboard-consorcio/dashboard-consorcio.component';
import { ListaBoletaCosorcioComponent } from './lista-boleta-consorcio/lista-boleta-consorcio.component';
import { ListaCaracteristicaProdutoCosorcioComponent } from './lista-caracteristica-produto-consorcio/lista-caracteristica-produto-consorcio.component';
import { ListaClienteCarteiraConsorcioComponent } from './lista-cliente-carteira-consorcio/lista-cliente-carteira-consorcio.component';
import { ListaEmpresaCosorcioComponent } from './lista-empresa-consorcio/lista-empresa-consorcio.component';
import { ListaTipoCosorcioComponent } from './lista-tipo-consorcio/lista-tipo-consorcio.component';
import { BoletaConsorcioService } from './services/boleta-consorcio.service';
import { CaracteristicaProdutoConsorcioService } from './services/caracteristica-produto-consorcio.service';
import { ClienteCarteiraConsorcioService } from './services/cliente-carteira-consorcio.service';
import { DashboardConsorcioService } from './services/dashboard-consorcio.service';
import { EmpresaConsorcioService } from './services/empresa-consorcio.service';
import { TipoConsorcioService } from './services/tipo-consorcio.service';
import { VisualizarBoletaConsorcioComponent } from './visualizar-boleta-consorcio/visualizar-boleta-consorcio.component';
import { VisualizarCarteiraConsorcioComponent } from './visualizar-carteira-consorcio/visualizar-carteira-consorcio.component';
import { OperadorCartaConsorcioService } from 'app/auxiliares/services/operador-carta-consorcio-service';
import { FechamentoCartaConsorcioService } from 'app/auxiliares/services/fechamento-carta-consorcio-service';
import { StatusCartaConsorcioService } from 'app/auxiliares/services/status-carta-consorcio-service';
import { ParceiroCartaConsorcioService } from 'app/auxiliares/services/parceiro-carta-consorcio-service';
import { CartaBoletaConsorcioService } from './services/carta-boleta-consorcio.service';
import { TipoLanceCartaConsorcioService } from './services/tipo-lance-carta-consorcio.service';
import { LanceCartaConsorcioService } from './services/lance-carta-consorcio.service';
import { CadastroLancesBoletaConsorcioComponent } from './cadastro-lances-boleta-consorcio/cadastro-lances-boleta-consorcio.component';
import { ListaCartaBoletaConsorcio } from './manter-carta-boleta-consorcio/lista-carta-boleta-consorcio.component';
import { CadastroCartaBoletaConsorcio } from './manter-carta-boleta-consorcio/cadastro-carta-boleta-consorcio.component';
import { FiltroCartaBoletaConsorcioComponent } from './manter-carta-boleta-consorcio/filtro-carta-boleta-consorcio/filtro-carta-boleta-consorcio.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ConsorcioRoutes),
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatRippleModule,
        MatDialogModule,
        BaseModule,
        FileUploadModule,
        MatMenuModule,
        CurrencyMaskModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
        NgxChartsModule,
        ChartsModule,
        FuseWidgetModule,
        DragDropModule,
        MovimentacaoModule,
        NgxShimmerLoadingModule,
        MatExpansionModule,
        MatTableModule
    ],
    declarations: [
        ListaTipoCosorcioComponent,
        CadastroTipoConsorcioComponent,
        ListaEmpresaCosorcioComponent,
        CadastroEmpresaConsorcioComponent,
        ListaCaracteristicaProdutoCosorcioComponent,
        CadastroCaracteristicaProdutoConsorcioComponent,
        ListaBoletaCosorcioComponent,
        CadastroBoletaConsorcioComponent,
        ListaClienteCarteiraConsorcioComponent,
        CadastroCarteiraConsorcioComponent,
        VisualizarBoletaConsorcioComponent,
        VisualizarCarteiraConsorcioComponent,
        DashboardConsorcioComponent,
        CadastroLancesBoletaConsorcioComponent,
        ListaCartaBoletaConsorcio,
        CadastroCartaBoletaConsorcio,
        FiltroCartaBoletaConsorcioComponent
    ],
    entryComponents: [
        CadastroTipoConsorcioComponent,
        CadastroEmpresaConsorcioComponent,
        CadastroCaracteristicaProdutoConsorcioComponent,
        CadastroBoletaConsorcioComponent,
        ListaClienteCarteiraConsorcioComponent,
        CadastroCarteiraConsorcioComponent,
        VisualizarBoletaConsorcioComponent,
        VisualizarCarteiraConsorcioComponent,
        CadastroCartaBoletaConsorcio,
        FiltroCartaBoletaConsorcioComponent
    ],
    providers: [
        AuthService,
        TipoConsorcioService,
        EmpresaConsorcioService,
        CaracteristicaProdutoConsorcioService,
        BoletaConsorcioService,
        ClienteService,
        StatusMovimentacaoService,
        ClienteCarteiraConsorcioService,
        EscritorioService,
        RegionalService,
        DashboardConsorcioService,
        OperadorCartaConsorcioService,
        FechamentoCartaConsorcioService,
        StatusCartaConsorcioService,
        ParceiroCartaConsorcioService,
        CartaBoletaConsorcioService,
        TipoLanceCartaConsorcioService,
        LanceCartaConsorcioService
    ],
})
export class ConsorcioModule {}
