import {
    Component,
    Inject,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatSnackBar
} from '@angular/material';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ClienteService } from 'app/clientes/services/cliente.service';
import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { ListaAtividadeAssuntoService } from '../services/lista-atividade-assunto.service';
import { ListaAtividadeService } from '../services/lista-atividades.service';
import { format } from 'date-fns';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';

export interface DialogData {
    id: number;
    is_alterar_data: boolean;
    is_assessor: boolean
}

interface Actions {
    buttonContactRegistryFiles: IItemsPermissionActions;
}

@Component({
    selector: 'cadastra-atividade-clientes',
    templateUrl: './cadastra-atividade-clientes.component.html',
    styleUrls: ['./cadastra-atividade-clientes.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CadastraAtividadeClientesComponent extends BaseComponent {
    _actions: Actions = {
        buttonContactRegistryFiles:{
            permission: 'Resgistrar Resposta Atividade',
            enabled: false
        },
    };

    form = new FormGroup ({
        nome: new FormControl("", [Validators.required]),
        cliente_id: new FormControl("", [Validators.required]),
        assunto_id: new FormControl("", [Validators.required]),
        data_hora_atividade: new FormControl("", [Validators.required]),
        cliente: new FormControl("", [Validators.required]),
        atividade_id: new FormControl(""),
        observacao: new FormControl(""),
        resposta: new FormControl(""),
        valor: new FormControl(0),
        isOportunidade: new FormControl(0),
        arquivos: new FormControl([]),
        is_assessor: new FormControl(true)
    });

    model: any = {
        cliente_id: "",
        resposta: "",
        observacao: "",
        assunto_id: "",
    };

    loadingLocal: any;
    loadingSpinnerAssunto: boolean = false;
    loadingSpinner: boolean = false;
    filteredOptions: Observable<string[]>;
    listAssunto: Object[] = [];

    arquivosSelecionados: File[] = [];
    usuario:any;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    constructor(
        public _service: ListaAtividadeService,
        public _assuntoService: ListaAtividadeAssuntoService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public _authService: AuthService,
        private _clienteService: ClienteService,
        public dialogRef: MatDialogRef<CadastraAtividadeClientesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
        if (this.data != null && this.data != undefined && this.data.id != null) {
            this.getById();
        }

        this.getAllAssuntos();
        
    }

    ngOnInit(): void {
        if(this.data != undefined && this.data.is_alterar_data != undefined && this.data.is_alterar_data){
            this.form.get('nome').disable();
            this.form.get('cliente_id').disable();
            this.form.get('assunto_id').disable();
            this.form.get('cliente').disable();
            this.form.get('resposta').disable();
            this.form.get('observacao').disable();
            this.form.get('valor').disable();
        }

        this.form.get('cliente').valueChanges.subscribe((query) => {
            if (typeof query === "string" || typeof query === "number") {
                this.loadingSpinner = true;
                this.params.search = query;
                this.filteredOptions = this._clienteService.search(this.params);
            }
        });

        // seta se a tarega é para assessor ou não
        this.form.get("is_assessor").setValue(this.data.is_assessor);
        
        this.verifyPermissionForActions();

        if (this._actions.buttonContactRegistryFiles.enabled) this.form.get('isOportunidade').setValue(2);
        else this.form.get('isOportunidade').setValue(0);

        this.form.get('isOportunidade').valueChanges.subscribe((isOportunidade) => {
            if (isOportunidade != 1) this.form.get('valor').setValue(0);
        });
    }

    verifyPermissionForActions(){
        this._actions.buttonContactRegistryFiles.enabled = this._authService
        .isPermissao([this._actions.buttonContactRegistryFiles.permission]);
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    isInvalidForm() {
        if (this.form.get('data_hora_atividade').value == null || this.form.get('data_hora_atividade').value == undefined) {
            return true;
        }

        if (this.form.get('assunto_id').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        if (this.form.get('cliente').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        if (this.form.get('valor').value == null || this.form.get('assunto_id').value == undefined) {
            return true;
        }

        return false;
    }

    salvar() {   

        this.openLoading();

        const formData = new FormData();

        formData.append("cliente_id", this.form.get("cliente").value.id);
        formData.append("assunto_id", this.form.get("assunto_id").value);
        formData.append("observacao", this.form.get("observacao").value);
        formData.append("resposta", this.form.get("resposta").value);
        formData.append("data_hora_atividade", format(this.form.get("data_hora_atividade").value, 'yyyy-MM-dd'));
        formData.append("valor_sugerido", this.form.get("valor").value);
        formData.append("is_assessor", this.form.get("is_assessor").value);
        // Adicionar os arquivos selecionados
        for (let i = 0; i < this.arquivosSelecionados.length; i++) {
            formData.append("arquivos[]", this.arquivosSelecionados[i]);
        }
        if (this.data == null || this.data.id == null) {
            this._service.create(formData).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        } else {
            this.model.id = this.data.id;
            formData.append("id", this.data.id.toString());
            formData.delete("resposta");
            this._service.update(this.data.id, formData).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById() {
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            (item) => {                
                this.form.get('data_hora_atividade').setValue( new Date(item.data.data_hora_atividade) );
                this.form.get('assunto_id').setValue(item.data.assunto_id);
                this.form.get('cliente').setValue(item.data.cliente);
                this.form.get('resposta').setValue(item.data.resposta);
                this.form.get('observacao').setValue(item.data.observacao);
                this.form.get('atividade_id').setValue(item.data.id);
                this.form.get('valor').setValue(item.data.valor);
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllAssuntos() {
        this.loadingSpinnerAssunto = true;
        this._assuntoService.getLista({is_subject_prosperidade: false}).subscribe(
            list => {
                this.listAssunto = list.data;
            },
            error => {
                this.loadingSpinnerAssunto = false;
            },
            () => {
                this.loadingSpinnerAssunto = false;
            });
    }

    getErrorAssuntoMessage() {
        return this.form.get('assunto_id').hasError('required') ? 'Assunto é um campo obrigatório' : '';
    }

    disabledItemByUpdateData(){
        if(this.data.is_alterar_data) return true;

        return false;
    }


    onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            for (let file of event.target.files) {
                this.arquivosSelecionados.push(file);
            }
        }
    }

    removerArquivo(index: number) {
        this.arquivosSelecionados.splice(index, 1);
    }
}
