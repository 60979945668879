import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { BaseComponent } from "app/base-module/base-component/base.component";
import { CartaBoletaConsorcioService } from "../services/carta-boleta-consorcio.service";
import { MatDialog, MatSnackBar } from "@angular/material";
import { AuthService } from "app/base-module/base-service/auth.service";
import { ICarta, ILance } from "../cadastro-boleta-consorcio/cadastro-boleta-consorcio.component";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TipoLanceCartaConsorcioService } from "../services/tipo-lance-carta-consorcio.service";
import { FechamentoCartaConsorcioService } from "app/auxiliares/services/fechamento-carta-consorcio-service";
import { StatusCartaConsorcioService } from "app/auxiliares/services/status-carta-consorcio-service";

@Component({
    selector: 'app-cadasto-carta-boleta-consorcio',
    templateUrl: './cadastro-carta-boleta-consorcio.component.html',
    styleUrls: ['./cadastro-carta-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroCartaBoletaConsorcio extends BaseComponent implements OnInit, OnChanges {

    listFechamentoConsorcio: any[] = [];
    loadingSpinnerFechamentoConsorcio: boolean = false;

    listStatusCartaConsorcio: any[] = [];
    loadingSpinnerStatusCartaConsorcio: boolean = false;

    @Input()
    cartaId: number | null;

    @Input()
    boletaId: number | null;

    @Input()
    cartaEdit: ICarta | null;

    @Input()
    parceiroConsorcioId: number;

    @Output() 
    addCartaEvent = new EventEmitter();

    @Output() 
    saveEditCartaEvent = new EventEmitter();

    model: ICarta;

    //carta consorcio
    cartaFormGroup:FormGroup;

    isEditCarta: boolean = false;
    selectCartaEdit: ICarta = null;
    selectCartaIndex: number = null;
    paramsCarta: any = {
        boleta_id: null,
        page: 0,
        per_page: 10
    };
    listTipoLance: any[] = [];
    loadingSpinnerTipoLance: boolean = false;

    constructor(
        public _service: CartaBoletaConsorcioService, 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService,
        private fb:FormBuilder,
        private ref: ChangeDetectorRef,
        private _tipoLanceCartaService: TipoLanceCartaConsorcioService,
        private _fechamentoCartaService: FechamentoCartaConsorcioService,
        private _statusCartaService: StatusCartaConsorcioService,
        public dialogRef: MatDialogRef<CadastroCartaBoletaConsorcio>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super(dialog, snackBar);
        this.cartaFormGroup = this.getNewFormGroup();
        if (data) {
            this.cartaId = data.cartaId;
            this.parceiroConsorcioId = data.parceiroConsorcioId;
            this.boletaId = data.boletaId;

            if(this.cartaId){
                console.log("chamando edição da carta")
                this.getById();
            }

            if(data.cartaEdit){
                setTimeout(()=>{
                    this.ref.markForCheck();
                    this.buildFormCarta(data.cartaEdit);
                    this.ref.detectChanges();
                },500)
                
            }
        }
    }
    
    /**
     * Método de inicialização da classe
     */
    ngOnInit(): void {
        this.newFormGroup();
        this.getAllTipoLance();
        this.getAllStatusCarta();
        this.getAllFechamento();
        this.addLance();
    }

    /**
     * Pega eventos de atualização dos parametros vindos do componente pai
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges): void {
        if(changes["parceiroConsorcioId"]){
            this.getAllFechamento()
        }
        if(changes["cartaEdit"]){
            if(this.cartaEdit.id){
                this.getById();
            }else{
                this.cartaFormGroup.get('id').setValue(this.cartaEdit.id),
                this.cartaFormGroup.get('grupo').setValue(this.cartaEdit.grupo);
                this.cartaFormGroup.get('cota').setValue(this.cartaEdit.cota);
                this.cartaFormGroup.get('prazo_grupo').setValue(Number(this.cartaEdit.prazo_grupo));
                this.cartaFormGroup.get('taxa_administrativa_grupo').setValue(this.cartaEdit.taxa_administrativa_grupo);
                this.cartaFormGroup.get('fechamento_id').setValue(this.cartaEdit.fechamento_id);
                this.cartaFormGroup.get('status_id').setValue(this.cartaEdit.status_id);
                this.cartaFormGroup.get('desconto').setValue(Number(this.cartaEdit.desconto));
                if(this.cartaEdit.data_fechamento){
                    this.cartaFormGroup.get('data_fechamento').setValue(new Date(this.cartaEdit.data_fechamento));
                }
                if(this.cartaEdit.data_pagamento){
                    this.cartaFormGroup.get('data_pagamento').setValue(new Date(this.cartaEdit.data_pagamento));
                }
                if(this.cartaEdit.valor_carta){
                    this.cartaFormGroup.get('valor_carta').setValue(Number(this.cartaEdit.valor_carta));
                }
                if(this.cartaEdit.valor){
                    this.cartaFormGroup.get('valor').setValue(Number(this.cartaEdit.valor));
                }
                // Atualizar o FormArray de Lances
                this.setLances(this.cartaEdit.lances);

                // adiciona um lance vazio para preenchimento;
                this.addLance();
            }
            this.getAllFechamento()
        }
        
    }

    /**
     * Exibe mensagem para forçar o usuário a selecionar um parceiro
     * @returns string
     */
    getErrorBoletaFormMessage() {
        return !this.parceiroConsorcioId? 'Preencha campo Parceiro da Boleta de Consórcio antes.' : '';
    }

    /**
     * Consulta a carta pelo ID
     */
    getById() {
        this.openLoading();
        this._service.getById(this.cartaId).subscribe(
            item => {
                this.closeLoading();
                this.model = item.data;
                this.buildFormCarta(item.data); 
            },
            error => {
                this.closeLoading();
                console.log(error);
            },
            () => {
                this.closeLoading();
                console.log("complete");
            }
        );
    }

    /**
     * Consulta lista de status da carta
     */
    getAllStatusCarta() {
        this.loadingSpinnerStatusCartaConsorcio = true;
        this._statusCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listStatusCartaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatusCartaConsorcio = false;
                console.log("complete");
            }
        );
    }

    /**
     * Consulta lista de fechamentos
     */
    getAllFechamento() {
        if(this.parceiroConsorcioId){
            this.loadingSpinnerFechamentoConsorcio = true;
            this._fechamentoCartaService.getAll({ no_paginate: true, parceiro_id: this.parceiroConsorcioId}).subscribe(
                list => {
                    this.listFechamentoConsorcio = list.data;
                },
                error => {
                },
                () => {
                    this.loadingSpinnerFechamentoConsorcio = false;
                    console.log("complete");
                }
            );
        }

    }

    /**
     * Retorna a lista de FormArray de lances
     */
    get lances(): FormArray {
        return this.cartaFormGroup.get('lances') as FormArray;
    }

    setLances(lances: ILance[]) {
        // Limpar os lances existentes
        this.lances.clear();
    
        // Adicionar os lances da carta selecionada ao FormArray
        if (lances && lances.length > 0) {
            lances.forEach((lance) => {
                if (
                    lance.tipo_lance_id != null && 
                    !isNaN(lance.tipo_lance_id) && 
                    lance.valor_lance != null && 
                    !isNaN(lance.valor_lance)
                ) {
                    this.lances.push(this.fb.group({
                        tipo_lance_id: [lance.tipo_lance_id, Validators.required],
                        valor_lance: [lance.valor_lance, Validators.required]
                    }));
                }
            });
        }
    }

    /**
     * Salva ou envia a carta para a Boleta
     */
    salvar() {
        console.log("salvando...")
        if(!this.cartaId && !this.boletaId){
            this.addCarta();
        }else{
            this.model = this.buildCarta();
            if(!this.model.id || this.cartaEdit){
                delete this.model.id;
            }
            this.openLoading();
            if(!this.cartaId){
                this._service.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close("reload-list");
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }else{
                this._service.update(this.cartaId, this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.dialogRef.close("reload-list");
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        }
    }

    addCarta() {
        if(this.cartaFormGroup.invalid){
            return;
        }
        const carta: ICarta = this.buildCarta();
        delete carta.id;
        this.dialogRef.close({carta});
    }

    saveEditCarta() {
        if(this.cartaFormGroup.invalid){
            return;
        }
        this.selectCartaEdit = null;
        this.isEditCarta = false;
        this.loadingIndicator = false;
        this.saveEditCartaEvent.emit(this.buildCarta());
    }

    getNewFormGroup(){
        return this.fb.group({
            id: new FormControl(null,[]),
            grupo: new FormControl('', [Validators.required]),
            cota: new FormControl('', [Validators.required]),
            prazo_grupo: new FormControl(null, [Validators.required]),
            taxa_administrativa_grupo: new FormControl(0),
            fechamento_id: new FormControl(),
            status_id: new FormControl(),
            desconto: new FormControl(0),
            data_fechamento: new FormControl(),
            data_pagamento: new FormControl(),
            valor_carta: new FormControl(0),
            lances: this.fb.array([]),
            valor: new FormControl(0),
        });
    }

    newFormGroup() {
        this.cartaFormGroup = this.getNewFormGroup();
    }

    getValorTotalLances() {
        return this.lances.controls.reduce((acc, item) => {
            if(item.value.valor_lance > 0){
                const tipo_valor = this.getTipoLanceById(item.value.tipo_lance_id);
                if(tipo_valor.tipo_valor == 'percent'){
                    return Number(acc) + ((Number(item.value.valor_lance)/100)*Number(this.cartaFormGroup.get('valor_carta').value));
                }
                return Number(acc) + Number(item.value.valor_lance);
            }
            return Number(acc)+0;
        }, 0);
    }

    newLance(): FormGroup {
        return this.fb.group({
            tipo_lance_id: '',
            valor_lance: ''
        })
    }

    addLance(): void {
        this.lances.push(this.newLance());
    }

    removeLance(index: number): void {
        this.lances.removeAt(index);
    }

    getLance(index: number) {
        return this.lances.controls[index];
    }

    getAllTipoLance() {
        this.loadingSpinnerTipoLance = true;
        this._tipoLanceCartaService.getAll({}).subscribe(
            list => {
                this.listTipoLance = list.data;
                this.loadingSpinnerTipoLance = false;
            },
            error => {
                this.loadingSpinnerTipoLance = false;
            },
            () => {
                this.loadingSpinnerTipoLance = false;
                console.log("complete");
            }
        );
    }

    getTipoLanceById(id:number){
        return this.listTipoLance.find(item => item.id == id);
    }

    getSimboloTipoValor(i: number): string{
        const tipo_lance = this.getTipoLanceById(this.getLance(i).value.tipo_lance_id)
        if(tipo_lance){
            return tipo_lance.tipo_valor == 'percent'? '% ' : 'R$ ';
        }
        return 'R$ ';
    }

    getOptionsTypeValue(i) {
        return { prefix: this.getSimboloTipoValor(i), thousands: '.', decimal: ',', align: 'center' }
    }

    // setAddCartaEvent(carta: ICarta) {
    //     this.addCartaEvent.emit({carta: carta});
    // }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    buildCarta(){
        const carta: ICarta = {
            grupo: this.cartaFormGroup.get('grupo').value,
            cota: this.cartaFormGroup.get('cota').value,
            prazo_grupo: this.cartaFormGroup.get('prazo_grupo').value,
            taxa_administrativa_grupo: this.cartaFormGroup.get('taxa_administrativa_grupo').value,
            fechamento_id: this.cartaFormGroup.get('fechamento_id').value,
            status_id: this.cartaFormGroup.get('status_id').value,
            desconto: this.cartaFormGroup.get('desconto').value,
            data_fechamento: this.cartaFormGroup.get('data_fechamento').value,
            data_pagamento: this.cartaFormGroup.get('data_pagamento').value,
            valor_carta: this.cartaFormGroup.get('valor_carta').value,
            valor: this.getValorTotalLances(),
            lances: this.lances.value,
            boleta_id: this.boletaId,
            id: this.cartaId,
        };

        return carta
    }

    buildFormCarta(data: ICarta){
        this.cartaFormGroup.get('grupo').setValue(data.grupo);
        this.cartaFormGroup.get('cota').setValue(data.cota);
        this.cartaFormGroup.get('prazo_grupo').setValue(data.prazo_grupo);
        this.cartaFormGroup.get('taxa_administrativa_grupo').setValue(data.taxa_administrativa_grupo);
        this.cartaFormGroup.get('fechamento_id').setValue(data.fechamento_id);
        this.cartaFormGroup.get('status_id').setValue(data.status_id);
        this.cartaFormGroup.get('desconto').setValue(data.desconto);
        this.cartaFormGroup.get('data_fechamento').setValue(data.data_fechamento? new Date(data.data_fechamento) : '');
        this.cartaFormGroup.get('data_pagamento').setValue(data.data_pagamento? new Date(data.data_pagamento) : '');
        this.cartaFormGroup.get('valor_carta').setValue(data.valor_carta);
        this.setLances(data.lances);
    }
}