import { Routes } from '@angular/router';
import { HomeComponent } from 'app/home/home/home.component';
import { AuthGuardService } from './base-module/base-service/auth-guard.service';

export const appRoutes: Routes = [
    {
        path: "home",
        redirectTo: "app-home",
        canActivate: [AuthGuardService],
    },
    {
        path: "",
        component: HomeComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: "auth/forgot-password",
        loadChildren: () =>
            import(
                "./main/authentication/forgot-password-2/forgot-password-2.module"
            ).then((m) => m.ForgotPassword2Module),
    },
    {
        path: "auth/reset-password/:token",
        loadChildren: () =>
            import(
                "./main/authentication/reset-password-2/reset-password-2.module"
            ).then((m) => m.ResetPassword2Module),
    },
    {
        path: "auth/login",
        loadChildren: () =>
            import("./main/authentication/login-2/login-2.module").then(
                (m) => m.Login2Module
            ),
    },
    {
        path: "auth/validate-token",
        loadChildren: () =>
            import(
                "./main/authentication/validate-token/validate-token.module"
            ).then((m) => m.ValidateTokenModule),
    },
    {
        path: "home",
        redirectTo: "app-home",
        canActivate: [AuthGuardService],
    },
    {
        path: "",
        component: HomeComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: "empresas",
        loadChildren: () =>
            import("./empresas/empresas.module").then((m) => m.EmpresasModule),
        canActivate: [AuthGuardService],
    },
    {
        path: "empreendimento",
        loadChildren: () =>
            import("./empreendimento/empreendimento.module").then(
                (m) => m.EmpreendimentoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "auxiliares",
        loadChildren: () =>
            import("app/auxiliares/auxiliares.module").then(
                (m) => m.AuxiliaresModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "clientes",
        loadChildren: () =>
            import("app/clientes/clientes.module").then(
                (m) => m.ClientesModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "movimentacao",
        loadChildren: () =>
            import("app/movimentacao/movimentacao.module").then(
                (m) => m.MovimentacaoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "renda-variavel",
        loadChildren: () =>
            import("app/renda-variavel/renda-variavel.module").then(
                (m) => m.RendaVariavelModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "renda-fixa",
        loadChildren: () =>
            import("app/renda-fixa/renda-fixa.module").then(
                (m) => m.RendaFixaModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "previdencia",
        loadChildren: () =>
            import("app/previdencia/previdencia.module").then(
                (m) => m.PrevidenciaModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "acao-comercial",
        loadChildren: () =>
            import("app/acao-comercial/acao-comercial.module").then(
                (m) => m.AcaoComercialModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "pre-agendamento",
        loadChildren: () =>
            import("app/pre-agendamento/pre-agendamento.module").then(
                (m) => m.PreAgendamentoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "patrimonial",
        loadChildren: () =>
            import("app/gestao-patrimonial/gestao-patrimonial.module").then(
                (m) => m.GestaoPatrimonialModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "notificacao",
        loadChildren: () =>
            import("app/notificacao/notificacao.module").then(
                (m) => m.NotificacaoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "parametro-sistema",
        loadChildren: () =>
            import("app/parametros/parametros.module").then(
                (m) => m.ParametrosModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "usuarios",
        loadChildren: () =>
            import("app/usuarios/usuarios.module").then(
                (m) => m.UsuariosModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "comissionamento",
        loadChildren: () =>
            import("app/comissionamento/comissionamento.module").then(
                (m) => m.ComissionamentoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "prospeccao-clientes",
        loadChildren: () =>
            import("app/prospeccao-clientes/prospeccao-clientes.module").then(
                (m) => m.ProspeccaoClienteModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "central-noticias",
        loadChildren: () =>
            import("app/central-noticias/central-noticias.module").then(
                (m) => m.CentralNoticiasModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "migracao",
        loadChildren: () =>
            import("app/migracao/migracao.module").then(
                (m) => m.MigracaoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "pre-agendamento",
        loadChildren: () =>
            import("app/pre-agendamento/pre-agendamento.module").then(
                (m) => m.PreAgendamentoModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "gerencial",
        loadChildren: () =>
            import("app/gerencial/gerencial.module").then(
                (m) => m.GerencialModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "relatorios",
        loadChildren: () =>
            import("app/relatorios/relatorios.module").then(
                (m) => m.RelatoriosModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: 'consorcio',
        loadChildren: () => import('app/consorcio/consorcio.module').then(m => m.ConsorcioModule),
        canActivate: [AuthGuardService]
    },
    {
        path: "lista-atividades",
        loadChildren: () =>
            import("app/lista-atividades/lista-atividades.module").then(
                (m) => m.ListaAtividadesModule
            ),
        canActivate: [AuthGuardService],
    },
    {
        path: "plano-saude",
        loadChildren: () => import('app/plano-saude/plano-saude.module').then(m => m.PlanoSaudeModule),
        canActivate: [AuthGuardService]
    },
    {
        path: "credito",
        loadChildren: () => import('app/credito/credito.module').then(m => m.CreditoModule),
        canActivate: [AuthGuardService]
    },
    {
        path: "conexoes-externas",
        loadChildren: () =>
            import("app/conexoes-externas/conexoes-externas.module").then(
                (m) => m.ConexoesExternasModule
            ),
        canActivate: [AuthGuardService],
    },
    // otherwise redirect to home
    {
        path: "**",
        redirectTo: "home",
        canActivate: [AuthGuardService],
    },
];

//export const routing = RouterModule.forRoot(appRoutes);
