import { Injectable}   from "@angular/core";
import { Observable }  from "rxjs";
import { HttpClient, HttpHeaders }  from "@angular/common/http";

import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";

@Injectable()
export class TarefasRespostaUsuarioEspecialista {

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService
    ) {}

    /**
     * Tipo de Oportunidade por id
     * @param id ID da Tipo de Oportunidade
     */
    getById(id): Observable<any> {
        return this._http.get(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas-respostas/" + id, { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Lista de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    getAll(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        
        return this._http.get(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas-respostas" + env,
            { headers: this.authService.getAuthHeaders() }
        );
    }
    /**
     * Delete de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    delete(id): Observable<any> {
        return this._http.delete(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas-respostas/" + id,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Cadastra Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    create(params): Observable<any> {
        return this._http.post(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas-respostas",
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Atualiza Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    update(id, params): Observable<any> {
        return this._http.put(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas-respostas/" + id,
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }
}

