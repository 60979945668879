import {
    Component,
    ViewChild,
    TemplateRef,
    ViewEncapsulation,
    OnInit
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { BaseComponent } from 'app/base-module/base-component/base.component';
import { StatusCartaConsorcioService } from 'app/auxiliares/services/status-carta-consorcio-service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'filtro-carta-boleta-consorcio',
    templateUrl: './filtro-carta-boleta-consorcio.component.html',
    styleUrls: ['./filtro-carta-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class FiltroCartaBoletaConsorcioComponent extends BaseComponent implements OnInit {
    model:any = {
        status_id: '',
        grupo: '',
        cota: ''
    }

    titulo:string = "Filtrar Cartas";
    usuario:any;
    listStatusCartaConsorcio: any[] = [];
    loadingSpinnerStatusCartaConsorcio: boolean = false;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltroCartaBoletaConsorcioComponent, { static: false }) table: FiltroCartaBoletaConsorcioComponent;

    formGroup:FormGroup;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroCartaBoletaConsorcioComponent>,
        private _authService: AuthService,
        private _statusCartaService: StatusCartaConsorcioService,
        private fb:FormBuilder
    ) {
        super(dialog, snackBar);
        this.usuario = JSON.parse(this._authService.getUser());
    }

    ngOnInit(): void {
        this.formGroup =  this.fb.group({
            grupo: new FormControl(),
            cota: new FormControl(),
            status_id: new FormControl(),
        });
        this.getAllStatusCarta();
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    filtrar(): void {
        this.dialogRef.close(this.formGroup.value)
    }

    /**
     * Consulta lista de status da carta
     */
    getAllStatusCarta() {
        this.loadingSpinnerStatusCartaConsorcio = true;
        this._statusCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listStatusCartaConsorcio = list.data;
            },
            error => {
                console.log(error);
            },
            () => {
                this.loadingSpinnerStatusCartaConsorcio = false;
                console.log("complete");
            }
        );
    }
}