import {
    Component, 
    ViewChild, 
    Input
}  from '@angular/core';
import {
    MatDialog, 
    MatSnackBar, 
    PageEvent, 
    MatPaginator
} from '@angular/material';

import { Router } from "@angular/router";
import {FormControl} from "@angular/forms";
import {SelectionType} from '@swimlane/ngx-datatable';

import {BaseComponent, ActionDialog} from "app/base-module/base-component/base.component";

import { AuthService } from 'app/base-module/base-service/auth.service';
import { ClienteProjetoService } from '../services/cliente-projeto.service';
import { CadastroClienteProjetosComponent } from '../cadastro-cliente-projeto/cadastro-cliente-projeto.component';
import { TipoProjetoService } from '../services/tipo-projeto.service';

@Component({
    selector: 'app-lista-cliente-projeto',
    templateUrl: './lista-cliente-projeto.component.html',
    styleUrls: ['./lista-cliente-projeto.component.scss'],
    providers: [TipoProjetoService]
})

export class ListaClienteProjetoComponent extends BaseComponent{
    
    params: any = {};
    user:any;
    loadingIndicator: boolean;
    public search = new FormControl();
    SelectionType = SelectionType;

    selected = [];
    data:any = {};
    titulo:string;

    model: any = {
        status_id: "",
        boletas:[]
    };

    _cliente_id:any = null;
    _tipo_projeto_id:any = null;
    @ViewChild('paginator',{ static: false }) paginator: MatPaginator;

    constructor(public service: ClienteProjetoService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private route: Router,
                private _authService: AuthService) {
        super(dialog, snackBar);

    }

    @Input() cliente_id: number;
    @Input() tipo_projeto_id: number;
    @Input() usuario_id: number;

    ngOnInit() {
        this.user = JSON.parse(this._authService.getUser());
        this.search.setValue('');
        this.getAll();      
    }

    /**
     * Evento de ordenação da lista
     * @param event
     */
    onSort(event:any){
        console.log(event)
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){

        this.params.page = (event.pageIndex+1);
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista
     */
    getAll() {
        if(this.search.value != null){
            this.params.search = this.search.value;
        }

        if(this.cliente_id != null && this.cliente_id != undefined){
            this.params.cliente_id = this.cliente_id;
        }

        if(this.tipo_projeto_id != null && this.tipo_projeto_id != undefined){
            this.params.tipo_projeto_id = this.tipo_projeto_id;
        }
        if(this.usuario_id != null && this.usuario_id != undefined){
            this.params.usuario_id = this.usuario_id;
        }

        this.loadingIndicator = true;
        this.service.getAll(this.params).subscribe(
            data => {
                                
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {                
                this.loadingIndicator = false;
                
                if(error.status == 401){        
                    this._authService.logout();
                    this.route.navigate(['/auth/login']);
                }
            },
            () => {
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui registro
     * @param id
     */
    delete(id) {

        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Projeto",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista.service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        error => {
                            lista.loadingIndicator = false;                            
                            lista.showError(error.error.message);

                        },
                        () => {                            
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    openCadastro(){
        let dialogRef = this.dialog.open(CadastroClienteProjetosComponent, {
            width: '500px',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                tipo_projeto_id: this.tipo_projeto_id,
                id: null
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Projeto cadastrada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar uma nova Projeto.");
            }
        });        
    }

    openAlterar(id){
        let dialogRef = this.dialog.open(CadastroClienteProjetosComponent, {
            width: '500px',
            data: {
                acao_comercial_id: null,
                cliente_id: this.cliente_id,
                tipo_projeto_id: this.tipo_projeto_id,
                id: id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Projeto atualizada com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar alterar uma nova Projeto.");
            }
        });     
    }
}
