import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { CadastroClienteSimplesComponent } from 'app/clientes/cadastro-cliente-simples/cadastro-cliente-simples.component';
import { FiltrarBoletaComponent } from 'app/movimentacao/filtrar-boleta/filtrar-boleta.component';
import { ActionDialog, BaseComponent } from '../../base-module/base-component/base.component';
import { CadastroBoletaConsorcioComponent } from '../cadastro-boleta-consorcio/cadastro-boleta-consorcio.component';
import { BoletaConsorcioService } from '../services/boleta-consorcio.service';
import { CartaBoletaConsorcioService } from '../services/carta-boleta-consorcio.service';
import { VisualizarBoletaConsorcioComponent } from '../visualizar-boleta-consorcio/visualizar-boleta-consorcio.component';


@Component({
    selector: "app-lista-boleta-consorcio",
    templateUrl: "./lista-boleta-consorcio.component.html",
    styleUrls: ["./lista-boleta-consorcio.component.scss"],
})
export class ListaBoletaCosorcioComponent extends BaseComponent {
    params: any = {};
    loadingIndicator: boolean;
    user: any;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaBoletaCosorcioComponent, { static: false })
    table: ListaBoletaCosorcioComponent;

    constructor(
        public _service: BoletaConsorcioService,
        public _cartaService: CartaBoletaConsorcioService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService
    ) {
        super(dialog, snackBar);

        this.getAll();
        this.user = JSON.parse(this._authService.getUser());
    }

    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event: PageEvent) {
        console.log(event);

        this.params.page = event.pageIndex + 1;
        this.params.per_page = event.pageSize;

        this.getAll();
    }

    /**
     * Consulta lista de Boleta Consórcio
     */
    getAll(): void {
        this.loadingIndicator = true;
        this._service.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {
                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    /**
     * Exclui Boleta Consórcio
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            data: {
                title: "Excluir Boleta Consórcio",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        (data) => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        (error) => {
                            lista.closeLoading();
                            console.log("error: " + error);
                            lista.showError(error.message);
                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                },
            },
        });
    }

    /**
     * Modal de cadastro
     */
    openCadastro(): void {
        let dialogRef = this.dialog.open(CadastroBoletaConsorcioComponent, {
            width: "800px",
            maxHeight: "99vh",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Boleta Consórcio cadastrado com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError(
                    "Ocorreu um erro ao tentar cadastrar um novo registro."
                );
            }
        });
    }

    /**
     * Modal de edição
     */
    openAlterar(id): void {
        let dialogRef = this.dialog.open(CadastroBoletaConsorcioComponent, {
            width: "800px",
            maxHeight: "99vh",
            data: {
                id: id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined && result != null && result != "cancel") {
                this.showSuccess("Boleta Consórcio atualizado com sucesso!");
                this.getAll();
            } else if (result != undefined && result != "cancel") {
                this.showError("Ocorreu um erro ao tentar altera o registro");
            }
        });
    }

    /**
     * Modal de Visualizar
     */
    openVisualizar(id): void {
        let dialogRef = this.dialog.open(VisualizarBoletaConsorcioComponent, {
            width: "800px",
            maxHeight: "99vh",
            data: {
                id: id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
        });
    }

    /**
     * Modal de Filtro
     */
    openFiltro(): void {
        let dialogRef = this.dialog.open(FiltrarBoletaComponent, {
            width: "700px",
            maxHeight: '99vh',
            data: {
                titulo: "Filtrar Boleta",
                status_boleta: true,
                status_ordem: false,
                tipo: "CS",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != "cancel" && result != undefined) {
                this.params.data_operacao_inicio = result.data_operacao_inicio;
                this.params.data_operacao_fim = result.data_operacao_fim;

                this.params.data_retorno_inicio = result.data_retorno_inicio? result.data_retorno_inicio : '';
                this.params.data_retorno_fim = result.data_retorno_fim? result.data_retorno_fim : '';

                if (result.cliente != undefined && result.cliente != null) {
                    this.params.cliente_id = result.cliente.id;
                }

                if (result.assessor_id != undefined && result.assessor_id != null) {
                    this.params.assessor_id = result.assessor_id;
                }

                if (result.escritorio_id != undefined && result.escritorio_id != null) {
                    this.params.escritorio_id = result.escritorio_id;
                }

                if (result.status_id != undefined && result.status_id != null) {
                    this.params.status_id = result.status_id;
                }

                if (result.acao_comercial_id != undefined && result.acao_comercial_id != null) {
                    this.params.acao_comercial_id = result.acao_comercial_id;
                }

                if (
                    result.tipo_consorcio_id != undefined &&
                    result.tipo_consorcio_id != null
                ) {
                    this.params.tipo_consorcio_id = result.tipo_consorcio_id;
                }

                if (result.tipo_lance_id != undefined && result.tipo_lance_id != null) {
                    this.params.tipo_lance_id = result.tipo_lance_id;
                }

                this.paginator.firstPage();
                this.getAll();
            }
        });
    }

    cadastroCliente():void {
        let dialogRef = this.dialog.open(CadastroClienteSimplesComponent, {
            width: '600px',  
            data: {
                abordagem: true
            }          
        });

        dialogRef.afterClosed().subscribe(result => {            
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cliente cadastrado com sucesso!");
                this.getAll();
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar cliente.");
            }
        });
        
    }

    /**
     * Exporta Boleta de Consórcio
     */
    export() {

        this.openLoading();
        this._service.export(this.params).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    /**
     * Exporta Boleta de Consórcio
     */
    cartasExport(id) {

        this.openLoading();
        this._cartaService.export({"boleta_id":id}).subscribe(
            data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    onSort(event:any){
        this.params.sortColumn = event.column.prop;
        this.params.sortOrder = event.newValue;

        this.getAll();
    }

    isRecorrente(is_recorrente) {
        if(is_recorrente==null || is_recorrente==""){
            return '';
        }

        return is_recorrente? 'SIM' : 'NÃO';
    }
}