import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar
} from '@angular/material';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { OperadorCartaConsorcioService } from 'app/auxiliares/services/operador-carta-consorcio-service';
import { ParceiroCartaConsorcioService } from 'app/auxiliares/services/parceiro-carta-consorcio-service';
import { StatusMovimentacaoService } from 'app/auxiliares/services/status-movimentacao.service';
import { CadastroClienteSimplesComponent } from 'app/clientes/cadastro-cliente-simples/cadastro-cliente-simples.component';
import { ClienteService } from 'app/clientes/services/cliente.service';
import { BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { BoletaConsorcioService } from '../services/boleta-consorcio.service';
import { CaracteristicaProdutoConsorcioService } from '../services/caracteristica-produto-consorcio.service';
import { EmpresaConsorcioService } from '../services/empresa-consorcio.service';
import { TipoConsorcioService } from '../services/tipo-consorcio.service';
import { CadastroCartaBoletaConsorcio } from '../manter-carta-boleta-consorcio/cadastro-carta-boleta-consorcio.component';
import { FiltroCartaBoletaConsorcioComponent } from '../manter-carta-boleta-consorcio/filtro-carta-boleta-consorcio/filtro-carta-boleta-consorcio.component';
import { StatusCartaConsorcioService } from 'app/auxiliares/services/status-carta-consorcio-service';
import { forEach } from 'lodash';


export interface ILance {
    tipo_lance_id: number;
    carta_id: number;
    valor_lance: number;
}

export interface ICarta {
    id?:number;
    grupo:string;
    cota:string;
    prazo_grupo:Date;
    taxa_administrativa_grupo:number;
    fechamento_id:number;
    status_id:number;
    desconto:number;
    data_fechamento:Date;
    data_pagamento: Date;
    valor_carta:number;
    lances?: ILance[];
    // lance:number;
    valor:number;
    boleta_id?: number | null;
}

interface IModel {
    status_id:number;
    parceiro_consorcio_id:number;
    operador_consorcio_id:number;
    cliente_id:number;
    tipo_consorcio_id:number;
    data_operacao:Date;
    data_retorno:Date;
    qtd_cartas:number;
    valor:number;
    observacao:string;
    cartas: ICarta[];
    is_recorrente: boolean | null;
    is_fixo_livre: string | null;
};

@Component({
    selector: 'app-cadastro-boleta-consorcio',
    templateUrl: './cadastro-boleta-consorcio.component.html',
    styleUrls: ['./cadastro-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class CadastroBoletaConsorcioComponent extends BaseComponent implements OnInit {
    loadingSpinner: boolean;
    filteredOptions: Observable<string[]>;
    boletaFormGroup:FormGroup = new FormGroup({
        cliente: new FormControl('', [Validators.required]),
        cliente_id: new FormControl('', [Validators.required]),
        status_id: new FormControl(null, [Validators.required]),
        produto_consorcio_id: new FormControl(null),
        valor: new FormControl(),
        observacao: new FormControl(),
        data_operacao: new FormControl(null, [Validators.required]),
        data_retorno: new FormControl(null),
        parceiro_consorcio_id: new FormControl(null, [Validators.required]),
        operador_consorcio_id: new FormControl(null, [Validators.required]),
        qtd_cartas: new FormControl(null),
        tipo_consorcio_id: new FormControl(null, [Validators.required]),
        is_recorrente: new FormControl(),
        is_fixo_livre: new FormControl()
    });
    
    listStatus: any[] = [];
    loadingSpinnerStatus: boolean = false;

    listProdutoConsorcio: any[] = [];
    loadingSpinnerProduto: boolean = false;
    isEfetivado:boolean = false;

    model:IModel = {
        status_id:null,
        parceiro_consorcio_id:null,
        operador_consorcio_id:null,
        cliente_id:null,
        tipo_consorcio_id:null,
        data_operacao:null,
        data_retorno:null,
        qtd_cartas:null,
        valor:null,
        observacao:null,
        cartas: [],
        is_recorrente: null,
        is_fixo_livre: null
    };

    loadingLocal:any;

    listTipoConsorcio: any[] = [];
    loadingSpinnerTipo: boolean = false;

    listEmpresaConsorcio: any[] = [];
    loadingSpinnerEmpresaConsorcio: boolean = false;

    listOperadorConsorcio: any[] = [];
    loadingSpinnerOperadorConsorcio: boolean = false;

    listFechamentoConsorcio: any[] = [];
    loadingSpinnerFechamentoConsorcio: boolean = false;

    listParceiroConsorcio: any[] = [];
    loadingSpinnerParceiroConsorcio: boolean = false;

    listStatusCartaConsorcio: any[] = [];
    loadingSpinnerStatusCartaConsorcio: boolean = false;

    data:any = {
        id:null
    };
    selectCartaEdit: ICarta = null;
    selectCartaIndex: number = null;
    paramsCarta: any = {
        boleta_id: null,
        page: 0,
        per_page: 10
    };
    listTipoLance: any[] = [];
    loadingSpinnerTipoLance: boolean = false;
    filterCarta: any = {
        grupo: '',
        cota: '',
        status_id: '',
        reload: ''
    }

    constructor(public service: BoletaConsorcioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                private _clienteService: ClienteService,
                private _statusService: StatusMovimentacaoService,
                private _produtoService: CaracteristicaProdutoConsorcioService,
                private _tipoService: TipoConsorcioService,
                private _empresaConsorcioService: EmpresaConsorcioService,
                public _Activatedroute: ActivatedRoute,
                private _parceiroCartaService: ParceiroCartaConsorcioService,
                private _operadorCartaService: OperadorCartaConsorcioService,
                private ref: ChangeDetectorRef,
                private route: Router,
                private _statusCartaService: StatusCartaConsorcioService,
            )
    {
        super(dialog, snackBar);
    }

    ngOnInit(): void {
        this.pageSize = this.paramsCarta.per_page;
        this.pageSizeOptions = [10, 20, 30, 100];
        
        if(this._Activatedroute.snapshot.params['id']){
            this.data.id = this._Activatedroute.snapshot.params['id'];
        }
        this.loadingIndicator=false;

        if(this.data.id){
            this.getById();
        }

        this.boletaFormGroup.get('cliente').valueChanges.subscribe(
            async query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = await this._clienteService.search(this.params);
                }
            }
        );

        this.getAllStatus();
        this.getAllProdutoConsorcio();
        this.getAllTipoConsorcio();
        this.getAllEmpresaConsorcio();
        this.getAllParceiroCarta();
        this.getAllOperador();
        this.getAllStatusCarta();
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    salvar(){
        this.model = this.buildModel();
        if(this.boletaFormGroup.valid){
            // if(this.model.cartas.length > 0){
            //     if(Number(this.model.qtd_cartas) !== this.model.cartas.length){
            //         this.showError("Quantidade de cartas diferente do total adicionado.");
            //         return;
            //     }

            //     // if(Number(this.model.valor) !== this.getSumValorTotalCartas()){
            //     //     this.showError("O campo Valor diferente do somatório do valor de cartas adicionadas.");
            //     //     return;
            //     // }
            // }
            this.openLoading();
            if(!this.data.id){
                this.service.create(this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.route.navigate(['/consorcio/lista-boleta-consorcio']);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }else{
                this.service.update(this.data.id, this.model).subscribe(
                    data => {
                        this.closeLoading();
                        this.route.navigate(['/consorcio/lista-boleta-consorcio']);
                    },
                    error => {
                        this.closeLoading();
                        this.showError(error.message);
                    },
                    () => {
                        this.closeLoading();
                    }
                );
            }
        }else{
            if(this.boletaFormGroup.invalid){
                this.validateAllFormFields(this.boletaFormGroup);
            }
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
            }
        });
    }

    getErrorClienteMessage() {
        return this.boletaFormGroup.get('cliente').hasError('required') ? 'Cliente é um campo obrigatório' : '';
    }

    getErrorStatusMessage() {
        return this.boletaFormGroup.get('status_id').hasError('required') ? 'Status é um campo obrigatório' : '';
    }

    getErrorProdutoMessage() {
        return this.boletaFormGroup.get('produto_consorcio_id').hasError('required') ? 'Produto é um campo obrigatório' : '';
    }

    getErrorValorMessage() {
        return this.boletaFormGroup.get('valor').hasError('required') ? 'Valor é um campo obrigatório' : '';
    }

    getById(){
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
         item => {
            // this.rows = this.buildCartas(item.data.cartas);
            this.boletaFormGroup.get('cliente').setValue(item.data.cliente);
            this.boletaFormGroup.get('cliente_id').setValue(item.data.cliente_id);
            this.boletaFormGroup.get('status_id').setValue(item.data.status_id);
            this.boletaFormGroup.get('produto_consorcio_id').setValue(item.data.produto_id);
            this.boletaFormGroup.get('valor').setValue(item.data.valor);
            this.boletaFormGroup.get('observacao').setValue(item.data.observacao);
            if(item.data.data_operacao){
                this.boletaFormGroup.get('data_operacao').setValue(new Date(item.data.data_operacao));
            }
            if(item.data.data_retorno){
                this.boletaFormGroup.get('data_retorno').setValue(new Date(item.data.data_retorno));
            }
            this.boletaFormGroup.get('parceiro_consorcio_id').setValue(item.data.parceiro_consorcio_id);
            this.boletaFormGroup.get('operador_consorcio_id').setValue(item.data.operador_consorcio_id);
            this.boletaFormGroup.get('qtd_cartas').setValue(item.data.qtd_cartas);
            this.boletaFormGroup.get('tipo_consorcio_id').setValue(item.data.tipo_consorcio_id);
            this.boletaFormGroup.get('is_recorrente').setValue(item.data.is_recorrente);
            this.boletaFormGroup.get('is_fixo_livre').setValue(item.data.is_fixo_livre);
        },
         error => {
            this.closeLoading();
            console.log(error);
         },
        () => {
            this.closeLoading();
            console.log("complete");
        });
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome;
        }
    }

    getCliente(cliente) {
        this.boletaFormGroup.get('cliente_id').setValue(cliente.id);
        this.loadingSpinner = false;
    }

    getAllStatus() {

        this.loadingSpinnerStatus = true;

        this._statusService.getLista({ tipo_produto: 'CS' }).subscribe(
            list => {
                this.listStatus = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatus = false;
            }
        );
    }

    getAllProdutoConsorcio() {

        this.loadingSpinnerProduto = true;

        this._produtoService.getLista({}).subscribe(
            list => {
                this.listProdutoConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerProduto = false;
                console.log("complete");
            }
        );
    }

    getAllTipoConsorcio() {
        this.loadingSpinnerTipo = true;
        this._tipoService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listTipoConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerTipo = false;
                console.log("complete");
            }
        );
    }

    getAllEmpresaConsorcio() {
        this.loadingSpinnerEmpresaConsorcio = true;
        this._empresaConsorcioService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listEmpresaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerEmpresaConsorcio = false;
                console.log("complete");
            }
        );
    }

    cadastroCliente():void {
        let dialogRef = this.dialog.open(CadastroClienteSimplesComponent, {
            width: '600px',  
            data: {
                abordagem: false
            }          
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != null && result != "cancel"){
                this.showSuccess("Cliente cadastrado com sucesso!");
            }else if(result != undefined && result != "cancel"){
                this.showError("Ocorreu um erro ao tentar cadastrar cliente.");
            }
        });
        
    }

    getAllParceiroCarta() {
        this.loadingSpinnerParceiroConsorcio = true;
        this._parceiroCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listParceiroConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerParceiroConsorcio = false;
                console.log("complete");
            }
        );
    }

    getAllOperador() {
        this.loadingSpinnerOperadorConsorcio = true;
        this._operadorCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listOperadorConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerOperadorConsorcio = false;
                console.log("complete");
            }
        );
    }

    buildModel(): IModel{
        console.log(this.rows);
        const buildModel:IModel = {
            ...this.boletaFormGroup.value,
            cartas: this.rows
        }
        return buildModel;
    }

    eventoEdicaoCarta (evento) {
        console.log(evento);
        // this.editCarta(evento.row, evento.index);
    }

    addCarta(event) {
        this.ref.markForCheck();
        this.rows.push(event.carta);
        this.rows = [...this.rows];
        this.temp = [...this.rows];
        this.length = this.rows.length;
        this.ref.detectChanges();
    }

    editCarta(row:ICarta, rowIndex){
        this.selectCartaEdit = row;
        this.selectCartaIndex = rowIndex;
        
        let dialogRef = this.dialog.open(CadastroCartaBoletaConsorcio, {
            width: "700px",
            maxHeight: '99vh',
            data: {
                boletaId: null,
                cartaId: null,
                parceiroConsorcioId: this.boletaFormGroup.get('parceiro_consorcio_id').value,
                cartaEdit: row
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if(result != 'cancel' && result != 'reload-list'){
                this.saveEditCarta(result);
            }
        });
        
    }

    deleteCarta(rowIndex: number) {
        // Marca estado inicial
        this.ref.markForCheck();
    
        // Remove o item da posição correta
        this.rows.splice(rowIndex, 1);
        
        // Cria uma nova referência para o array
        this.rows = [...this.rows];
        
        // Atualiza o comprimento da lista
        this.length = this.rows.length;
        
        // Força a detecção de mudanças
        this.ref.detectChanges();
    }

    saveEditCarta(event) {
        this.deleteCarta(this.selectCartaIndex);
        this.selectCartaEdit = null;
        this.ref.markForCheck();
        this.rows.push(event.carta);
        this.rows = [...this.rows];
        this.temp = [...this.rows];
        this.length = this.rows.length;
        this.ref.detectChanges();
    }

    openCadastroCarta(): void {
        let dialogRef = this.dialog.open(CadastroCartaBoletaConsorcio, {
            width: "800px",
            maxHeight: '99vh',
            data: {
                boletaId: this.data.id? this.data.id : null,
                cartaId: this.selectCartaEdit? this.selectCartaEdit.id : null,
                parceiroConsorcioId: this.boletaFormGroup.get('parceiro_consorcio_id').value,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if(result != 'cancel' && result != 'reload-list'){
                this.addCarta(result);
            }

            if(result == 'reload-list'){
                this.filterCarta = {
                    grupo: result.grupo? result.grupo : '',
                    cota: result.cota? result.cota : '',
                    status_id: result.status_id? result.status_id : '',
                    reload: !this.filterCarta.reload
                }
            }
        });
    }

    openFiltroCarta(): void {
        let dialogRef = this.dialog.open(FiltroCartaBoletaConsorcioComponent, {
            width: "700px",
            maxHeight: '99vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if(result != 'cancel'){
                this.filterCarta = {
                    grupo: result.grupo? result.grupo : '',
                    cota: result.cota? result.cota : '',
                    status_id: result.status_id? result.status_id : '',
                    reload: true
                }
            }
        });
    }

    getAllStatusCarta() {
        this.loadingSpinnerStatusCartaConsorcio = true;
        this._statusCartaService.getAll({ no_paginate: true }).subscribe(
            list => {
                this.listStatusCartaConsorcio = list.data;
            },
            error => {
            },
            () => {
                this.loadingSpinnerStatusCartaConsorcio = false;
                console.log("complete");
            }
        );
    }

    getNameStatusCartaById(id: number) {
        let nome = '';
        for (let i = 0; i < this.listStatusCartaConsorcio.length; i++) {
            const element = this.listStatusCartaConsorcio[i];
            if (id === element.id) {
                nome = element.nome;
                break; // Interrompe o loop quando o id é encontrado
            }
        }
        return nome;
    }
}
