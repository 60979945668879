import { Injectable}   from "@angular/core";
import { Observable }  from "rxjs";
import { HttpClient, HttpHeaders }  from "@angular/common/http";

import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";

@Injectable()
export class ListaAtividadeService {

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService
    ) {}

    /**
     * Tipo de Oportunidade por id
     * @param id ID da Tipo de Oportunidade
     */
    getById(id): Observable<any> {
        return this._http.get(
            this.utilitesService.urlPrefix + "lista-atividades/atividades/" + id, { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Lista de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    getAll(params): Observable<any> {
        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";
        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        
        return this._http.get(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas" + env,
            { headers: this.authService.getAuthHeaders() }
        );
    }
    /**
     * Delete de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    delete(id): Observable<any> {
        return this._http.delete(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas/" + id,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Cadastra Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    create(params): Observable<any> {
        return this._http.post(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas",
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Atualiza Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    update(id, params): Observable<any> {
        return this._http.put(
            this.utilitesService.urlPrefix + "lista-atividades/tarefas/" + id,
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Exporta Lista em Excel
     * @param id ID
     */
    getAllExport(params): Observable<any> {

        var paramHttp = this.utilitesService.formurlencoded(params);
        var env = "";

        if (paramHttp != null && paramHttp != "") {
            env = "?" + paramHttp;
        }
        return this._http.get(this.utilitesService.urlPrefix + "lista-atividades/cliente/export" + env, {
            responseType: "arraybuffer",
            headers: new HttpHeaders({
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    } 

    /**
     * Atualiza Resposta da atividade
     * @param params Json Resposta
     */
    updateResposta(id, params): Observable<any> {
        return this._http.patch(
            this.utilitesService.urlPrefix + "lista-atividades/atividades/" + id+"/cliente/update-resposta",
            JSON.stringify(params),
            { headers: this.authService.getAuthHeaders() }
        );
    }
}
