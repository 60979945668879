import {
    Component,
    Inject,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatSnackBar
} from '@angular/material';

import { FormControl, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

import { ActionDialog, BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";
import { AuthService } from "../../base-module/base-service/auth.service";
import { TarefaArquivosService } from '../services/tarefa-arquivos.service';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';

export interface DialogData {
    id: number;
}

interface Actions {
    buttonListFiles: IItemsPermissionActions;
    buttonNewFiles: IItemsPermissionActions;
    buttonDeleteFiles: IItemsPermissionActions;
    buttonDownloadFiles: IItemsPermissionActions;
}

@Component({
    selector: 'app-upload-tarefas-arquivos.component',
    templateUrl: './upload-tarefas-arquivos.component.html',
    styleUrls: ['./upload-tarefas-arquivos.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class UploadTarefasArquivosComponent extends BaseComponent {
    form = new FormGroup ({
        arquivos: new FormControl([])
    });

    loadingLocal: any;
    arquivosSelecionados: File[] = [];
    listaArquivos = [];

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;

    _actions: Actions = {
        buttonListFiles:{
            permission: 'Listar Arquivos da Lista de Atividades',
            enabled: false
        },
        buttonNewFiles:{
            permission: 'Cadastrar Arquivos da Lista de Atividades',
            enabled: false
        },
        buttonDeleteFiles:{
            permission: 'Excluir Arquivos da Lista de Atividades',
            enabled: false
        },
        buttonDownloadFiles:{
            permission: 'Download de Arquivos da Lista de Atividades',
            enabled: false
        },
    }; 

    constructor(
        public _service: TarefaArquivosService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public _authService: AuthService,
        public dialogRef: MatDialogRef<UploadTarefasArquivosComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);
        if (this.data != null && this.data != undefined && this.data.id != null) {
            this.getById();
        }
        
    }

    ngOnInit(): void {
        this.verifyPermissionForActions();
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    verifyPermissionForActions(){
        this._actions.buttonDownloadFiles.enabled = this._authService
        .isPermissao([this._actions.buttonDownloadFiles.permission]);

        this._actions.buttonNewFiles.enabled = this._authService
        .isPermissao([this._actions.buttonNewFiles.permission]);

        this._actions.buttonDeleteFiles.enabled = this._authService
        .isPermissao([this._actions.buttonDeleteFiles.permission]);

        this._actions.buttonListFiles.enabled = this._authService
        .isPermissao([this._actions.buttonListFiles.permission]);
    }

    salvar() {   

        this.openLoading();
        const formData = new FormData();

        // Adicionar os arquivos selecionados
        for (let i = 0; i < this.arquivosSelecionados.length; i++) {
            formData.append("arquivos[]", this.arquivosSelecionados[i]);
        }
        
        this._service.create(this.data.id, formData).subscribe(
            (data) => {
                this.showSuccess("Arquivo(s) anexado(s) com sucesso.");
                this.closeLoading();
                this.dialogRef.close(data);
            },
            (error) => {
                this.closeLoading();
                this.showError(error.error.message);
            },
            () => {
                this.closeLoading();
            }
        );
    
    }

    getById() {
        this.openLoading();
        this._service.getAll({"tarefa_id": this.data.id}).subscribe(
            (response) => {                
                this.listaArquivos = response.data;
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            for (let file of event.target.files) {
                this.arquivosSelecionados.push(file);
            }
        }
    }

    removerArquivo(index: number) {
        this.arquivosSelecionados.splice(index, 1);
    }

    /**
     * 
     * @param id 
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: '250px',
            disableClose: true,
            panelClass: 'modal-delete',
            data: {
                title: "Excluir Arquivo",
                text: "Deseja realmente excluir este arquivo, esta operação não poderá ser desfeita?",
                callback: function () {
                    // lista.dialog.closeAll();
                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        data => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getById();
                        },
                        error => {
                            lista.closeLoading();
                            lista.showError(error.error.message);

                        },
                        () => {
                            lista.loadingIndicator = false;
                        }
                    );
                }
            }
        });
    }

    download(id, path) {
        this.openLoading();
        this._service.download(id).subscribe(
            (response: any) => {
                // Criar um Blob a partir da resposta
                const blob = new Blob([response], { type: response.type });

                // Criar URL do blob
                const url = window.URL.createObjectURL(blob);

                // Definir nome do arquivo
                let fileName = path.replace("tarefas/", "");

                // Criar um link para download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                // Remover o link após o download
                document.body.removeChild(link);
            },
            error => {
                this.showError(error.error.message);
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }
    
}
