import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';;
import { ActionDialog, BaseComponent } from "../../base-module/base-component/base.component";
import { CartaBoletaConsorcioService } from '../services/carta-boleta-consorcio.service';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ICarta } from '../cadastro-boleta-consorcio/cadastro-boleta-consorcio.component';
import { CadastroCartaBoletaConsorcio } from './cadastro-carta-boleta-consorcio.component';


@Component({
    selector: 'app-lista-carta-boleta-consorcio',
    templateUrl: './lista-carta-boleta-consorcio.component.html',
    styleUrls: ['./lista-carta-boleta-consorcio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: []
})

export class ListaCartaBoletaConsorcio extends BaseComponent implements OnInit, OnChanges {
    loadingIndicator: boolean;
    user:any;


    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(ListaCartaBoletaConsorcio, { static: false }) table: ListaCartaBoletaConsorcio;

    @Input()
    boletaId: string;

    @Input()
    parceiroConsorcioId: string;

    @Input()
    filter: any = {}

    @Output() eventoEdicaoCarta = new EventEmitter();

    constructor(public _service: CartaBoletaConsorcioService, 
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _authService: AuthService) {
        super(dialog, snackBar);

        this.pageSizeOptions = [10, 25, 50, 100, 200];
        this.pageSize = 10;
        this.params.per_page = this.pageSize;
    }
    
    ngOnInit(): void {
        this.user = JSON.parse(this._authService.getUser());
        this.params.boleta_id = this.boletaId;
        this.getAll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.filter){
            this.params = {
                ...this.params,
                ...changes.filter.currentValue,
                boleta_id: this.boletaId
            };
            this.getAll();
        }
    }


    /**
     * Evento da paginação
     * @param {PageEvent} event
     */
    pageEvent(event:PageEvent){
        this.params.page = (event.pageIndex + 1);
        this.params.per_page = event.pageSize;
        this.params.boleta_id = this.boletaId

        this.getAll();
    }

    /**
     * Consulta lista de Cartas
     */
    getAll(): void {
        this.loadingIndicator = true;
        this._service.getAll(this.params).subscribe(
            data => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            error => {
                console.log("error: " + error);
                this.loadingIndicator = false;
            },
            () => {

                console.log("complete");
                this.loadingIndicator = false;
            }
        );
    }

    enviarEventoEdicao(row: ICarta, index) {
        this.eventoEdicaoCarta.emit({row: row, index});
    }

    openAlterarCarta(cartaId: number): void {
        let dialogRef = this.dialog.open(CadastroCartaBoletaConsorcio, {
            width: "800px",
            maxHeight: '99vh',
            data: {
                boletaId: this.boletaId,
                cartaId,
                parceiroConsorcioId: this.parceiroConsorcioId,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if(result == 'reload-list'){
                this.getAll();
            }
        });
    }

    /**
     * Exclui Carta
     * @param id
     */
    delete(id) {
        var lista = this;
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            data: {
                title: "Excluir Carta",
                text: "Deseja realmente excluir este item?",
                callback: function () {
                    lista.dialog.closeAll();

                    lista.loadingIndicator = true;
                    lista._service.delete(id).subscribe(
                        (data) => {
                            lista.loadingIndicator = false;
                            lista.showSuccess(data.message);
                            lista.getAll();
                        },
                        (error) => {
                            lista.closeLoading();
                            console.log("error: " + error);
                            lista.showError(error.message);
                        },
                        () => {
                            console.log("complete");
                            lista.loadingIndicator = false;
                        }
                    );
                },
            },
        });
    }
}
