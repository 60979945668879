import { Injectable}   from "@angular/core";
import { Observable }  from "rxjs";
import { HttpClient, HttpHeaders }  from "@angular/common/http";

import { UtilitesService } from "../../base-module/base-service/utilites.service";
import { AuthService } from "../../base-module/base-service/auth.service";

@Injectable()
export class TarefaArquivosService {

    constructor(
        private authService: AuthService,
        private _http: HttpClient,
        public utilitesService: UtilitesService
    ) {}


    /**
     * Lista de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    getAll(params): Observable<any> {
        return this._http.get(
            this.utilitesService.urlPrefix + `lista-atividades/arquivos/${params.tarefa_id}`,
            { headers: this.authService.getAuthHeaders() }
        );
    }
    /**
     * Delete de Tipo de Oportunidade
     * @param id ID da Tipo de Oportunidade
     */
    delete(id): Observable<any> {
        return this._http.delete(
            this.utilitesService.urlPrefix + "lista-atividades/arquivos/" + id,
            { headers: this.authService.getAuthHeaders() }
        );
    }

    /**
     * Cadastra Tipo de Oportunidade
     * @param params Json Tipo de Oportunidade
     */
    create(id: number, params: FormData): Observable<any> {
        return this._http.post(
            this.utilitesService.urlPrefix + `lista-atividades/arquivos/${id}`,
            params,
            { 
                headers: new HttpHeaders({
                    'Access-Control-Allow-Origin': "*",
                    'Authorization':'Bearer '+this.authService.getToken()
                })
            }
        );
    }

    /**
     * download do arquivo
     * @param id ID
     */
    download(id): Observable<Blob> {
        return this._http.get(this.utilitesService.urlPrefix + `lista-atividades/arquivos/download/${id}`, {
            responseType: "blob",
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.authService.getToken(),
                "Access-Control-Allow-Origin": "*"
            })
        });
    }
    
}
