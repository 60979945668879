import {
    Component, 
    ViewChild, 
    TemplateRef, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import { 
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl, Validators} from '@angular/forms';
import { BaseComponent, LoadingTeste} from "app/base-module/base-component/base.component";
import {AuthService} from "app/base-module/base-service/auth.service";
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { TipoPatrimonioService } from '../services/tipo-patrimonio.service';
import { ClientePatrimonioService } from '../services/cliente-patrimonio.service';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface DialogData {
    id: any;
    cliente_id: any;
    is_financeiro: boolean;
}

@Component({
    selector: 'app-cadastro-cliente-patrimonio',
    templateUrl: './cadastro-cliente-patrimonio.component.html',
    styleUrls: ['./cadastro-cliente-patrimonio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
      ]
})

export class CadastroClientePatrimoniosComponent extends BaseComponent {
    @ViewChild(MatDatepicker, { static: false }) picker: MatDatepicker<any>;
    usuario:any;
    min_ano = new Date();

    // Campos comum nos patrimonios financeiros ou não
    is_financeiro  = new FormControl(true, [Validators.required]);
    tipo_patrimonio_id = new FormControl('', []);
    descricao = new FormControl('', [Validators.required]);
    valor  = new FormControl('0', [Validators.required]);
    
    // Campos patrimonios Financeiros
    instituicao_financeira = new FormControl('', [Validators.required]);
    vencimento = new FormControl(moment(), [Validators.required]);
    open_finance = new FormControl(true, [Validators.required])
    finalidade = new FormControl('', [Validators.required]);
    
    // Campos patrimonios Financeiros
    saldo_devedor  = new FormControl('0', [Validators.required]);

    model:any = {
        usuario_id: "",
        is_financeiro:"",
        tipo_patrimonio_id:"",
        descricao:"",
        valor:"",
		instituicao_financeira: "",
		vencimento: "",
		open_finance: "",
		finalidade: "",
		saldo_devedor: "",
    };

    loadingLocal: any;
    titulo: string;
    filteredOptions: Observable<string[]>;
    loadingSpinner: boolean = false;
    
    loadingSpinnerTipoPatrimonio: boolean = false;
    listaTipoPatrimonio: any[] = [];

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;   

    constructor(public _service: ClientePatrimonioService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private loading: MatDialog,
                public authService: AuthService,
                public dialogRef: MatDialogRef<CadastroClientePatrimoniosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                public _tipoPatrimonioService: TipoPatrimonioService)
    {
        super(dialog, snackBar);
        
        this.getTipoPatrimonio();

        this.model.cliente_id = this.data.cliente_id;
        if( this.data.id != null){
            this.getById();
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {

        this.loadingLocal =  this.loading.open(LoadingTeste, {
            width: '150px',
            disableClose: true
        });

    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if(this.loadingLocal !=null){
            this.loadingLocal.close();
        }
    }

    isInvalidForm(){

        if (!this.descricao.valid){
            return true;
        }
        
        if (!this.valor.valid) {
            return true;
        }

        return false;
    }

    salvar(){ 
        this.openLoading();
        
        this.model = {
            usuario_id: JSON.parse(this.authService.getUser()).id,
            is_financeiro: this.is_financeiro.value,
            descricao: this.descricao.value,
            tipo_patrimonio_id: this.tipo_patrimonio_id.value,
            valor: this.valor.value,
            instituicao_financeira: this.instituicao_financeira.value,
            vencimento: this.vencimento.value._d,
            open_finance: this.open_finance.value,
            finalidade: this.finalidade.value,
            saldo_devedor: this.saldo_devedor.value,
            cliente_id: this.data.cliente_id,
        };
        
        if(this.data.id == null){
        
            this._service.create(this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }else{
            
            this._service.update(this.data.id, this.model).subscribe(
                data => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                error => {
                    this.closeLoading();
                    this.showError(error.error.message);
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getById(){
        this.openLoading();
        this._service.getById(this.data.id).subscribe(
            item => {
                this.model = item.data;
                this.descricao.setValue(item.data.descricao);
                this.tipo_patrimonio_id.setValue(item.data.tipo_patrimonio_id);
                this.valor.setValue(item.data.valor);
                this.is_financeiro.setValue(item.data.is_financeiro);
                this.instituicao_financeira.setValue(item.data.instituicao_financeira);
                this.vencimento.setValue(item.data.vencimento);
                this.open_finance.setValue(item.data.open_finance);
                this.finalidade.setValue(item.data.finalidade);
                this.saldo_devedor.setValue(item.data.saldo_devedor);
            },
            error => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );

    }

    getTipoPatrimonio() {
        this.loadingSpinnerTipoPatrimonio = true;
        this._tipoPatrimonioService.getAll(this.params).subscribe({
            next: (request) => {
              this.listaTipoPatrimonio = request.data; // Atualiza a variável apenas após a resposta
              this.loadingSpinnerTipoPatrimonio = false;
            },
            error: (err) => {
                console.error('Erro ao buscar dados:', err);
                this.loadingSpinnerTipoPatrimonio = false;
            }
          });
    }

    changePatrimonioFinanceiro() {
        this.params.financeiro = this.is_financeiro.value;
        this.getTipoPatrimonio();
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.vencimento.value;
        ctrlValue.year(normalizedYear.year());
        this.vencimento.setValue(ctrlValue);
    }
    
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.vencimento.value;
        ctrlValue.month(normalizedMonth.month());
        this.vencimento.setValue(ctrlValue);
        datepicker.close();
    }

    listaTipoPatrimonioFiltrada() {
        return this.listaTipoPatrimonio.filter(tp => tp.financeiro == this.is_financeiro.value);
    }
}

